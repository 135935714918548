import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects';
import { Manager, Socket } from 'socket.io-client';

import {
  addProduct,
  removeProduct,
  validateDiscountCode,
} from './reducers/basket/reducer';
import { eventSend } from './reducers/event/reducer';
import { sendMessage } from './reducers/messenger/reducer';
import { create } from './reducers/order/reducer';
import { load as loadProduct } from './reducers/product/reducer';
import { load as loadProducts, loadBybrand } from './reducers/products/reducer';
import {
  getProducts,
  putReview,
  submitReview,
} from './reducers/review/reducer';
import { loadAllReviews } from './reducers/reviews/reducer';
import { query } from './reducers/search/reducer';
import { send } from './reducers/send-request';
import {
  changeCurrencyType,
  changeLanguageType,
} from './reducers/user/reducer';

const manager = new Manager(window.location.origin);
const socket = manager.socket('/');

export const getSocket = (): Socket => socket;

const watch = function* (): Generator<ForkEffect<never>, void, string> {
  yield takeEvery(addProduct.type, send, socket);
  yield takeEvery(create.type, send, socket);
  yield takeEvery(eventSend.type, send, socket);
  yield takeEvery(getProducts.type, send, socket);
  yield takeEvery(changeCurrencyType.type, send, socket);
  yield takeEvery(changeLanguageType.type, send, socket);
  yield takeEvery(loadAllReviews.type, send, socket);
  yield takeEvery(loadBybrand.type, send, socket);
  yield takeEvery(loadProduct.type, send, socket);
  yield takeEvery(loadProducts.type, send, socket);
  yield takeEvery(putReview.type, send, socket);
  yield takeEvery(query.type, send, socket);
  yield takeEvery(removeProduct.type, send, socket);
  yield takeEvery(sendMessage.type, send, socket);
  yield takeEvery(submitReview.type, send, socket);
  yield takeEvery(validateDiscountCode.type, send, socket);
};

export const mySaga = function* (): Generator<
  AllEffect<Generator<ForkEffect<never>, void, string>>,
  void,
  void
> {
  yield all([watch()]);
};
