import React, { useCallback, useState } from 'react';

import Star from './star';

function Stars({
  setField,
  fieldName,
  initialRating,
  isDisabled,
}: {
  setField?: (field: string, value: number) => void;
  fieldName?: string;
  initialRating: number;
  isDisabled?: boolean;
}): JSX.Element {
  const [rating, setRating] = useState(initialRating);
  const [hovered, setHovered] = useState(0);

  const setFieldRating = useCallback(
    (value: number): void => {
      if (setField) {
        setRating(value);
        if (fieldName) {
          setField(fieldName, value);
        }
      }
    },
    [setField, fieldName],
  );

  const setIsHovered: (isHovered: boolean, step: number) => void = useCallback(
    (isHovered: boolean, step: number): void => {
      if (isHovered) {
        setHovered(step);
      } else {
        setHovered(0);
      }
    },
    [],
  );

  return (
    <div className={`stars${isDisabled ? ' disabled' : ''}`}>
      <Star
        key={1}
        isActive={
          (rating >= 1 && rating <= 5) || (hovered >= 1 && hovered <= 5)
        }
        step={1}
        onClick={setFieldRating}
        setIsHovered={isDisabled ? undefined : setIsHovered}
      />
      <Star
        key={2}
        isActive={
          (rating >= 2 && rating <= 5) || (hovered >= 2 && hovered <= 5)
        }
        step={2}
        onClick={setFieldRating}
        setIsHovered={isDisabled ? undefined : setIsHovered}
      />
      <Star
        key={3}
        isActive={
          (rating >= 3 && rating <= 5) || (hovered >= 3 && hovered <= 5)
        }
        step={3}
        onClick={setFieldRating}
        setIsHovered={isDisabled ? undefined : setIsHovered}
      />
      <Star
        key={4}
        isActive={
          (rating >= 4 && rating <= 5) || (hovered >= 4 && hovered <= 5)
        }
        step={4}
        onClick={setFieldRating}
        setIsHovered={isDisabled ? undefined : setIsHovered}
      />
      <Star
        key={5}
        isActive={rating === 5 || hovered === 5}
        step={5}
        onClick={setFieldRating}
        setIsHovered={isDisabled ? undefined : setIsHovered}
      />
    </div>
  );
}

export default Stars;
