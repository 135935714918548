import { type Action, type Store } from '@reduxjs/toolkit';
import { Socket as SocketIO } from 'socket.io-client';

import { initState } from './reducers/basket/reducer';
import { clientInformation, tick } from './reducers/config/reducer';
import { initDelivery } from './reducers/country/reducer';
import {
  adminIsAvailable,
  messengerInitState,
  sendMessageResponse,
} from './reducers/messenger/reducer';
import { sendRaw } from './reducers/send-request';

export default class Socket {
  constructor(
    public readonly socket: SocketIO,
    public readonly store: Store,
  ) {}

  async requests(): Promise<void> {
    const requestInterval = setInterval((): void => {
      if (this.socket.connected) {
        sendRaw(this.socket, {
          type: initState.type,
        }).then((response: Action): Action => this.store.dispatch(response));

        sendRaw(this.socket, {
          type: messengerInitState.type,
        }).then((messenger: Action): Action => this.store.dispatch(messenger));

        sendRaw(this.socket, {
          type: initDelivery.type,
        }).then((country: Action): Action => this.store.dispatch(country));

        this.sendTick();
        this.sendClientInformation();

        clearInterval(requestInterval);
      }
    }, 100);

    setInterval((): void => this.sendTick(), 5000);
  }

  async subscribe(): Promise<void> {
    this.socket.on(`message`, (message): void => {
      this.store.dispatch({
        type: sendMessageResponse.type,
        payload: {
          ...message,
        },
      });
    });

    this.socket.on(
      'event.messenger.isAvailable',
      (isAvailable: boolean): void => {
        this.store.dispatch({
          type: adminIsAvailable.type,
          payload: {
            isAvailable,
          },
        });
      },
    );
  }

  sendTick(): void {
    sendRaw(this.socket, {
      type: tick.type,
    });
  }

  sendClientInformation(): void {
    if (typeof window !== 'undefined') {
      const { screen } = window;

      sendRaw(this.socket, {
        type: clientInformation.type,
        payload: {
          width: screen.width,
          height: screen.height,
          colorDepth: screen.colorDepth,
          availWidth: screen.availWidth,
          availHeight: screen.availHeight,
          pixelDepth: screen.pixelDepth,
        },
      });
    }
  }
}
