import { createSlice } from '@reduxjs/toolkit';

import { type IProductState, Load, type LoadResponse } from './types';

const initialState: IProductState = {
  isLoading: true,
  product: null,
};

const productSlice = createSlice({
  name: 'Product',
  initialState,
  reducers: {
    reset: (state): void => {
      state.isLoading = true;
      state.product = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    load: (state, _action: Load): void => {
      state.isLoading = true;
    },
    loadResponse: (state, action: LoadResponse): void => {
      state.product = action.payload.product;
      state.isLoading = false;
      state.product?.photos.sort((a, b) => a.position - b.position);
    },
  },
});

export const { reset, load, loadResponse } = productSlice.actions;

export default productSlice.reducer;
