import {
  EEventContextAction,
  EEventType,
  type IEshopReview,
  type TEshopReviewDescription,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Add } from '../components/icons/add';
import LinearProgress from '../components/linear-progress';
import Stars from '../components/review/stars';
import { eventSend } from '../reducers/event/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { setActiveCategory } from '../reducers/products/reducer';
import { loadAllReviews } from '../reducers/reviews/reducer';
import { type IReviewsState } from '../reducers/reviews/types';

function ReviewsContainer({
  dispatch,
  Reviews,
  Language,
}: {
  dispatch: Dispatch;
  Reviews: IReviewsState;
  Language: ILanguageState;
}): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_REVIEWS,
        action: EEventContextAction.ENTER,
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_REVIEWS,
          action: EEventContextAction.LEAVE,
        }),
      );
    };
  }, [dispatch]);

  useEffect((): void => {
    if (!Reviews.reviews) {
      dispatch(loadAllReviews());
    }
  }, [dispatch, Reviews.reviews]);

  if (!Reviews.reviews) {
    return <LinearProgress />;
  }

  return (
    <div className="container--5 container--t container--center review">
      <h1>{t('reviews.title')}</h1>

      {Reviews.reviews.map((review: IEshopReview): JSX.Element => {
        const key: string = `${review.date}-${review.name}-${review.rating}`;

        const header: string = `${review.flag} ${review.name?.trim()}`;

        let description: TEshopReviewDescription | undefined;
        description = review.descriptions?.find(
          (d: TEshopReviewDescription): boolean =>
            d.languageType.code === Language.languageType.code,
        );

        if (
          !description &&
          review.descriptions &&
          review.descriptions.length > 0
        ) {
          [description] = review.descriptions;
        }

        const prosEls: JSX.Element[] = [];
        const consEls: JSX.Element[] = [];

        if (description) {
          description.pros?.forEach((pro: string): void => {
            prosEls.push(
              <div
                key={pro}
                className="review review--pros-cons-list review--pros-icon"
              >
                <Add />
                <span>{pro}</span>
              </div>,
            );
          });

          description.cons?.forEach((con: string): void => {
            consEls.push(
              <div
                key={con}
                className="review review--pros-cons-list review--cons-icon"
              >
                <Add />
                <span>{con}</span>
              </div>,
            );
          });
        }

        return (
          <div
            key={key}
            style={{
              marginBottom: '4rem',
              paddingBottom: '2rem',
              borderBottom: '1px solid #1a237e',
            }}
          >
            <div>
              <p style={{ width: '70%', display: 'inline-block' }}>{header}</p>
              <p
                style={{
                  textAlign: 'right',
                  width: '30%',
                  display: 'inline-block',
                }}
              >
                {review.date}
              </p>
            </div>

            {review.rating && (
              <Stars initialRating={review.rating} isDisabled />
            )}

            {description && (
              <p style={{ fontStyle: 'italic', padding: '0 2rem 0 2rem' }}>
                {description.description}
              </p>
            )}

            {description && (description.pros || description.cons) && (
              <div className="container">
                <div className="container--5">
                  <h4>{t('review.pros')}</h4>
                  {prosEls}
                </div>
                <div className="container--5">
                  <h4>{t('review.cons')}</h4>
                  {consEls}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = ({
  Reviews,
  Language,
}: {
  Reviews: IReviewsState;
  Language: ILanguageState;
}) => ({
  Reviews,
  Language,
});

export default connect(mapStateToProps)(ReviewsContainer);
