import { createSlice } from '@reduxjs/toolkit';

import { type ICurrencyState } from './types';

const initialState: ICurrencyState = {
  currencies: [],
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initCurrency: (_state: ICurrencyState): void => {},
  },
});

export const { initCurrency } = currencySlice.actions;

export default currencySlice.reducer;
