import { createSlice } from '@reduxjs/toolkit';

import { type ISearchState, SearchQuery, SearchQueryResponse } from './types';

const initialState: ISearchState = {
  query: '',
  products: [],
};

const searchSlice = createSlice({
  name: 'Search',
  initialState,
  reducers: {
    clearResults: (state): void => {
      state.query = '';
      state.products = [];
    },
    query: (state, action: SearchQuery): void => {
      state.query = action.payload.query;
    },
    queryResponse: (state, action: SearchQueryResponse): void => {
      if (action.payload.query === state.query) {
        state.products = action.payload.products;
        state.products.forEach((product) => {
          product.photos.sort((a, b) => a.position - b.position);
        });
      }
    },
  },
});

export const { clearResults, query, queryResponse } = searchSlice.actions;

export default searchSlice.reducer;
