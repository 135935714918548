import {
  type IPhotoDimension,
  type IProductList,
  type IReviewDescription,
  type IReviewProduct,
  type IReviewProductValidationErrors,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Form, Formik, type FormikProps, type FormikValues } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { type IConfigState } from '../../reducers/config/types';
import { type ILanguageState } from '../../reducers/language/types';
import { setFields } from '../../reducers/review/reducer';
import { type IReviewState } from '../../reducers/review/types';
import Input from '../input';
import { ButtonAdd } from './button-add';
import { ButtonRemove } from './button-remove';
import Stars from './stars';

const PROS_CONS_COUNT = 10;
const PHOTO_WIDTH = 300;

function ReviewStepTwo({
  Config,
  dispatch,
  Language,
  Review,
  setReviewStep,
}: {
  Config: IConfigState;
  dispatch: Dispatch;
  Language: ILanguageState;
  Review: IReviewState;
  setReviewStep: (step: number) => void;
}): JSX.Element {
  const { t } = useTranslation();

  const [productContent, setProductContent] = useState<
    JSX.Element[] | undefined
  >([]);
  const [prosProductCount, setProsProductCount] = useState(
    new Map<string, number>(),
  );
  const [consProductCount, setConsProductCount] = useState(
    new Map<string, number>(),
  );
  const [isValidForms, setIsValidForms] = useState(true);

  const prosProductCountSet = useCallback(
    (productUUID: string, value = 0, diff = 0): void =>
      setProsProductCount(
        (prevState: Map<string, number>): Map<string, number> => {
          const newState = new Map(prevState);

          let newValue: number = value;

          if (value && diff) {
            const diffNewValue: number = value + diff;
            newValue =
              diffNewValue > PROS_CONS_COUNT ? PROS_CONS_COUNT : diffNewValue;
          } else if (value) {
            newValue = value > PROS_CONS_COUNT ? PROS_CONS_COUNT : value;
          } else if (diff) {
            const diffNewValue: number =
              (prevState.get(productUUID) || 0) + diff;
            newValue =
              diffNewValue > PROS_CONS_COUNT ? PROS_CONS_COUNT : diffNewValue;
          }

          newState.set(productUUID, newValue);
          return newState;
        },
      ),
    [setProsProductCount],
  );

  const consProductCountSet = useCallback(
    (productUUID: string, value = 0, diff = 0): void =>
      setConsProductCount(
        (prevState: Map<string, number>): Map<string, number> => {
          const newState = new Map(prevState);

          let newValue: number = value;

          if (value && diff) {
            const diffNewValue: number = value + diff;
            newValue =
              diffNewValue > PROS_CONS_COUNT ? PROS_CONS_COUNT : diffNewValue;
          } else if (value) {
            newValue = value > PROS_CONS_COUNT ? PROS_CONS_COUNT : value;
          } else if (diff) {
            const diffNewValue: number =
              (prevState.get(productUUID) || 0) + diff;
            newValue =
              diffNewValue > PROS_CONS_COUNT ? PROS_CONS_COUNT : diffNewValue;
          }

          newState.set(productUUID, newValue);

          return newState;
        },
      ),
    [setConsProductCount],
  );

  useEffect((): void => {
    if (Review.review?.languageType) {
      prosProductCount.forEach((value: number, key: string): void => {
        if (!Review.review?.languageType) {
          return;
        }

        dispatch(
          setFields({
            languageType: Review.review.languageType,
            productUUID: key,
            productProsCount: value,
          }),
        );
      });

      consProductCount.forEach((value: number, key: string): void => {
        if (!Review.review?.languageType) {
          return;
        }

        dispatch(
          setFields({
            languageType: Review.review.languageType,
            productUUID: key,
            productConsCount: value,
          }),
        );
      });
    }
  }, [
    consProductCount,
    prosProductCount,
    Review.review?.languageType,
    dispatch,
  ]);

  useEffect((): void => {
    const pContent: JSX.Element[] | undefined = Review.products?.map(
      (product: IProductList): JSX.Element => {
        const name: ITranslation | undefined = product.names.find(
          ({ code }: ITranslation): boolean =>
            code === Language.languageType.code,
        );

        const reviewProduct: IReviewProduct | undefined =
          Review.review?.products.find(
            ({ product: p }: IReviewProduct): boolean =>
              p.uuid === product.uuid,
          );

        const d: IReviewDescription | undefined =
          reviewProduct?.descriptions.find(
            ({ languageType }: IReviewDescription): boolean =>
              languageType.code === Language.languageType.code,
          );

        const [photo] = product.photos;
        let photoHeight: number = PHOTO_WIDTH;

        if (photo) {
          photoHeight =
            photo.dimension?.find(
              (photoDimension: IPhotoDimension): boolean =>
                photoDimension.width === PHOTO_WIDTH,
            )?.height || PHOTO_WIDTH;
        }

        let prosArray: string[] = d?.pros || [];
        let consArray: string[] = d?.cons || [];

        const prosLength: number = Math.max(
          prosProductCount.get(product.uuid) || 1,
          prosArray.length,
        );
        const consLength: number = Math.max(
          consProductCount.get(product.uuid) || 1,
          consArray.length,
        );

        prosArray = [
          ...prosArray,
          ...Array.from(Array(PROS_CONS_COUNT - prosLength).keys()).map(
            (): string => '',
          ),
        ];
        consArray = [
          ...consArray,
          ...Array.from(Array(PROS_CONS_COUNT - consLength).keys()).map(
            (): string => '',
          ),
        ];

        let formRef: FormikProps<FormikValues> | null = null;

        return (
          <div key={product.uuid} className="review__product">
            <h3>{name?.value}</h3>

            <img
              alt={name?.value}
              title={name?.value}
              src={`${Config.cdn}/static/product/photo/${photo.uuid}--${PHOTO_WIDTH}.webp`}
              loading="lazy"
              width={PHOTO_WIDTH}
              height={photoHeight}
              className="review review--image"
            />

            <Formik
              innerRef={(formikRef) => {
                formRef = formikRef;
              }}
              enableReinitialize
              initialValues={{
                rating: reviewProduct?.rating || 0,
                description: d?.description || '',
                pros: prosArray,
                cons: consArray,
                prosL: prosLength,
                consL: consLength,
              }}
              onSubmit={(_values, { setSubmitting }) => {
                setSubmitting(false);
              }}
              validate={(values) => {
                let isValid: boolean = true;
                const { rating, description, pros, cons } = values;

                const errors: IReviewProductValidationErrors = {
                  rating: '',
                  description: '',
                  pros: [],
                  cons: [],
                };

                if (description.trim().length > 500) {
                  errors.description = t('review.descriptionIsTooLong');
                  isValid = false;
                }

                pros.forEach((pro: string, i: number): void => {
                  if (pro.trim().length > 500) {
                    errors.pros[i] = t('review.proIsTooLong', { i: i + 1 });
                    isValid = false;
                  }
                });

                cons.forEach((con: string, i: number): void => {
                  if (con.trim().length > 500) {
                    errors.cons[i] = t('review.conIsTooLong', { i: i + 1 });
                    isValid = false;
                  }
                });

                const { languageType } = Language;

                dispatch(
                  setFields({
                    languageType,
                    productUUID: product.uuid,
                    productRating: rating,
                    productDescription: description,
                    productPros: pros,
                    productCons: cons,
                    productProsCount: prosProductCount.get(product.uuid),
                    productConsCount: consProductCount.get(product.uuid),
                  }),
                );

                setIsValidForms(isValid);

                return isValid ? {} : errors;
              }}
            >
              {({ errors, values }) => (
                <Form>
                  <Stars
                    setField={(field: string, value: number) =>
                      formRef?.setFieldValue(field, value)
                    }
                    fieldName="rating"
                    initialRating={values.rating}
                  />

                  <Input
                    errorMessage={errors?.description}
                    name="description"
                    label={t('review.fieldDescription')}
                    type="textarea"
                  />

                  <div className="review__pros">
                    <h4>{t('review.pros')}</h4>

                    {Array.from(Array(values.prosL).keys()).map(
                      (i: number): JSX.Element => (
                        <Input
                          key={i}
                          errorMessage={errors?.pros && errors.pros[i]}
                          name={`pros[${i}]`}
                          label={t('review.pro', { index: i + 1 })}
                          type="text"
                        />
                      ),
                    )}

                    <div className="container container--6 container--center">
                      <ButtonAdd
                        onClick={(): void =>
                          prosProductCountSet(product.uuid, values.prosL, 1)
                        }
                        text={t('review.addPros')}
                        isShown={prosLength < PROS_CONS_COUNT}
                      />

                      <ButtonRemove
                        onClick={(): void =>
                          prosProductCountSet(product.uuid, values.prosL, -1)
                        }
                        text={t('review.removePros')}
                        isShown={prosLength > 1}
                      />
                    </div>
                  </div>

                  <div className="review__cons">
                    <h4>{t('review.cons')}</h4>

                    {Array.from(Array(values.consL).keys()).map(
                      (i: number): JSX.Element => (
                        <Input
                          key={i}
                          errorMessage={errors?.cons && errors.cons[i]}
                          name={`cons[${i}]`}
                          label={t('review.con', { index: i + 1 })}
                          type="text"
                        />
                      ),
                    )}

                    <div className="container container--6 container--center">
                      <ButtonAdd
                        onClick={(): void =>
                          consProductCountSet(product.uuid, values.consL, 1)
                        }
                        text={t('review.addCons')}
                        isShown={consLength < PROS_CONS_COUNT}
                      />

                      <ButtonRemove
                        onClick={(): void =>
                          consProductCountSet(product.uuid, values.consL, -1)
                        }
                        text={t('review.removeCons')}
                        isShown={consLength > 1}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        );
      },
    );

    setProductContent(pContent);
  }, [
    Config.cdn,
    Language.languageType.code,
    prosProductCountSet,
    consProductCountSet,
    Review.products,
    Review.review,
    t,
    prosProductCount,
    consProductCount,
    setReviewStep,
    dispatch,
    Language,
  ]);

  return (
    <>
      <p>{t('review.productIntro')}</p>

      {productContent}

      <br />
      <br />

      <button
        className="button--primary button--centered"
        type="button"
        onClick={(): void | boolean => isValidForms && setReviewStep(3)}
      >
        {t('review.goToNextPage')}
      </button>

      <br />
      <br />
      <button
        className="button--primary button--half"
        onClick={(): void => setReviewStep(1)}
        type="button"
      >
        {t('review.goToPreviousPage')}
      </button>
    </>
  );
}

const mapStateToProps = ({
  Review,
  Language,
  Config,
}: {
  Review: IReviewState;
  Language: ILanguageState;
  Config: IConfigState;
}) => ({
  Review,
  Language,
  Config,
});

export default connect(mapStateToProps)(ReviewStepTwo);
