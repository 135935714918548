import {
  EEventContextAction,
  EEventType,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getHead } from '../../head';
import { eventSend } from '../reducers/event/reducer';
import { setActiveCategory } from '../reducers/products/reducer';

function DeliveryContainer({ dispatch }: { dispatch: Dispatch }) {
  useEffect((): (() => void) => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_TERMS_AND_CONDITION,
        action: EEventContextAction.ENTER,
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_TERMS_AND_CONDITION,
          action: EEventContextAction.LEAVE,
        }),
      );
    };
  }, [dispatch]);

  const { t } = useTranslation();

  useEffect((): void => {
    const { title } = getHead(t, t('navigation.title.termsAndConditions'));
    document.title = title;
  }, [t]);

  return (
    <>
      <h1>{t('termsAndConditions.title')}</h1>

      <h2>{t('termsAndConditions.seller')}</h2>
      <p>{t('termsAndConditions.sellerDescription')}</p>

      <h2>{t('termsAndConditions.goods')}</h2>
      <p>{t('termsAndConditions.goodsDescription')}</p>

      <h2>{t('termsAndConditions.payments')}</h2>
      <p>{t('termsAndConditions.paymentsDescription')}</p>
      <br />
      <p>Comgate Payments, a.s., Gočárova třída 1754 / 48b, Hradec Králové</p>
      <p>
        Comgate email support:{' '}
        <a
          href="mailto:platby-podpora@comgate.cz"
          rel="noreferrer noopener nofollow"
        >
          platby-podpora@comgate.cz
        </a>
      </p>
      <p>
        Comgate support:{' '}
        <a href="tel:00420228224267" rel="noreferrer noopener nofollow">
          +420 228 224 267
        </a>
      </p>
      <p>
        Manual for card payments:{' '}
        <a
          href="https://help.comgate.cz/v1/docs/en/payments-by-a-card"
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          https://help.comgate.cz/v1/docs/en/payments-by-a-card
        </a>
      </p>
      <p>
        Manual for bank transfer payments:{' '}
        <a
          href="https://help.comgate.cz/docs/en/bank-transfers"
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          https://help.comgate.cz/docs/en/bank-transfers
        </a>
      </p>

      <h2>{t('termsAndConditions.delivery')}</h2>
      <p>{t('termsAndConditions.deliveryDescription')}</p>

      <h2>{t('termsAndConditions.complaints')}</h2>

      <h3>{t('termsAndConditions.complaintsShortened')}</h3>
      <p>{t('termsAndConditions.complaintsDescription')}</p>
      <h3>{t('termsAndConditions.complaintsSection.one.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.one.description')}</p>
      <h4>{t('termsAndConditions.complaintsSection.one.list.header')}</h4>
      <ul>
        <li>
          <p>{t('termsAndConditions.complaintsSection.one.list.one')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.one.list.two')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.one.list.three')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.one.list.four')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.one.list.five')}</p>
        </li>
      </ul>

      <h3>{t('termsAndConditions.complaintsSection.two.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.two.description')}</p>

      <h3>{t('termsAndConditions.complaintsSection.three.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.three.description')}</p>

      <h3>{t('termsAndConditions.complaintsSection.four.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.four.description')}</p>

      <h3>{t('termsAndConditions.complaintsSection.five.header')}</h3>
      <h4>{t('termsAndConditions.complaintsSection.five.list.header')}</h4>
      <ul>
        <li>
          <p>{t('termsAndConditions.complaintsSection.five.list.one')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.five.list.two')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.five.list.three')}</p>
        </li>
        <li>
          <p>{t('termsAndConditions.complaintsSection.five.list.four')}</p>
        </li>
      </ul>

      <p>{t('termsAndConditions.complaintsSection.five.description')}</p>

      <h3>{t('termsAndConditions.complaintsSection.six.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.six.description')}</p>

      <h3>{t('termsAndConditions.complaintsSection.seven.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.seven.description')}</p>

      <h3>{t('termsAndConditions.complaintsSection.eight.header')}</h3>
      <p>{t('termsAndConditions.complaintsSection.eight.description')}</p>

      <h2>{t('termsAndConditions.privacyPolicy.header')}</h2>

      <h3>{t('termsAndConditions.privacyPolicy.section.one.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.one.description')}</p>

      <h3>{t('termsAndConditions.privacyPolicy.section.two.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.two.description')}</p>

      <h3>{t('termsAndConditions.privacyPolicy.section.three.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.three.description')}</p>

      <h3>{t('termsAndConditions.privacyPolicy.section.four.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.four.description')}</p>

      <h3>{t('termsAndConditions.privacyPolicy.section.five.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.five.description')}</p>

      <h3>{t('termsAndConditions.privacyPolicy.section.six.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.six.description')}</p>

      <h3>{t('termsAndConditions.privacyPolicy.section.seven.header')}</h3>
      <p>{t('termsAndConditions.privacyPolicy.section.seven.description')}</p>

      <br />
      <br />
      <br />
      <br />

      <h4>Heureka CZ</h4>
      <p>
        Vaši spokojenost s nákupem zjišťujeme prostřednictvím e-mailových
        dotazníků v rámci programu Ověřeno zákazníky, do něhož je náš e-shop
        zapojen. Ty vám zasíláme pokaždé, když u nás nakoupíte, pokud ve smyslu
        § 7 odst. 3 zákona č. 480/2004 Sb. o některých službách informační
        společnosti jejich zasílání neodmítnete. Zpracování osobních údajů pro
        účely zaslání dotazníků v rámci programu Ověřeno zákazníky provádíme na
        základě našeho oprávněného zájmu, který spočívá ve zjišťování vaší
        spokojenosti s nákupem u nás. Pro zasílání dotazníků, vyhodnocování vaší
        zpětné vazby a analýz našeho tržního postavení využíváme zpracovatele,
        kterým je provozovatel portálu Heureka.cz; tomu pro tyto účely můžeme
        předávat informace o zakoupeném zboží a vaši e-mailovou adresu. Vaše
        osobní údaje nejsou při zasílání e-mailových dotazníků předány žádné
        třetí straně pro její vlastní účely. Proti zasílání e-mailových
        dotazníků v rámci programu Ověřeno zákazníky můžete kdykoli vyjádřit
        námitku odmítnutím dalších dotazníků pomocí odkazu v e-mailu s
        dotazníkem. V případě vaší námitky vám dotazník nebudeme dále zasílat.
      </p>
      <br />
      <span>
        Sounds from{' '}
        <a
          href="https://notificationsounds.com/"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          Notification Sounds
        </a>
        .
      </span>
    </>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DeliveryContainer);
