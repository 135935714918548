import { type IMessage } from '@mahawi/eshop-common/dist/src/types';
import { createSlice } from '@reduxjs/toolkit';

import {
  type IMessengerState,
  type InitStateResponse,
  type IsAvailable,
  type SendMessage,
  type SendMessageResponse,
} from './types';

const initialState: IMessengerState = {
  inProgress: false,
  isAvailable: false,
  message: '',
  messages: [],
  lastMessageUUID: '',
};

const sortMessages = (messages: IMessage[]): IMessage[] => {
  return messages.sort((a: IMessage, b: IMessage): number => {
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    if (a.timestamp < b.timestamp) {
      return -1;
    }

    return 0;
  });
};

const messengerSlice = createSlice({
  name: 'Messenger',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    messengerInitState: (_state, _action): void => {},

    initStateResponse: (state, action: InitStateResponse): void => {
      state.messages = sortMessages(action.payload.messages);
    },
    sendMessage: (state, action: SendMessage): void => {
      state.inProgress = true;
      state.message = action.payload.message;
    },
    sendMessageResponse: (state, action: SendMessageResponse): void => {
      state.messages.push(action.payload.message);

      if (action.payload.message.direction === 'out') {
        state.lastMessageUUID = action.payload.message.uuid;
      }

      state.inProgress = false;
      state.message = '';
      state.messages = sortMessages(state.messages);
    },
    adminIsAvailable: (state, action: IsAvailable): void => {
      state.isAvailable = action.payload.isAvailable;
    },
  },
});

export const {
  initStateResponse,
  sendMessage,
  messengerInitState,
  sendMessageResponse,
  adminIsAvailable,
} = messengerSlice.actions;

export default messengerSlice.reducer;
