import { createSlice } from '@reduxjs/toolkit';

import {
  ChangeCurrencyType,
  ChangeLanguageType,
  type IUserState,
} from './types';

export const initialState: IUserState = {
  currencyType: null,
  languageType: null,
  uuid: null,
};

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    changeCurrencyType: (state, action: ChangeCurrencyType): void => {
      state.currencyType = action.payload.currencyType;
    },
    changeLanguageType: (state, action: ChangeLanguageType): void => {
      state.languageType = action.payload.languageType;
    },
  },
});

export const { changeCurrencyType, changeLanguageType } = userSlice.actions;

export default userSlice.reducer;
