import { currencyFormat } from '@mahawi/eshop-common/dist/src/currency-format';
import {
  EEventContextAction,
  EEventType,
  type ICountry,
  type ICustomerCurrencyType,
  type IDelivery,
  type ILanguageType,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { type IBasketState } from '../reducers/basket/types';
import { type IConfigState } from '../reducers/config/types';
import { type ICountryState } from '../reducers/country/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { eventSend } from '../reducers/event/reducer';
import { setLanguage } from '../reducers/language/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { type IProductState } from '../reducers/product/types';
import { query } from '../reducers/search/reducer';
import {
  changeCurrencyType,
  changeLanguageType,
} from '../reducers/user/reducer';
import { type IUserState } from '../reducers/user/types';
import RopereLogo from './ropere-logo';

function Topbar({
  Basket,
  Currency,
  dispatch,
  Language,
  Product,
  User,
  Config,
  Country,
}: {
  Basket: IBasketState;
  Currency: ICurrencyState;
  User: IUserState;
  dispatch: Dispatch;
  Language: ILanguageState;
  Product: IProductState;
  Config: IConfigState;
  Country: ICountryState;
}): JSX.Element {
  const location = useLocation();
  const { i18n: i, t } = useTranslation();

  const [, , path, identifier] = location.pathname.split('/');

  const handleSearchQueryChange = useCallback(
    (value: string): void => {
      dispatch(
        query({
          query: value,
          languageType: {
            code: Language.languageType.code,
          },
        }),
      );
    },
    [dispatch, Language.languageType.code],
  );

  const changeLanguage = useCallback(
    (code: string): void => {
      const languageType: ILanguageType | undefined = Language.languages.find(
        (l: ILanguageType): boolean => l.code === code,
      );

      if (!languageType) {
        return;
      }

      dispatch(setLanguage({ languageType }));
      i.changeLanguage(languageType.code.toLowerCase());
    },
    [dispatch, i, Language.languages],
  );

  const currency: ICustomerCurrencyType | undefined = Currency.currencies.find(
    (c: ICustomerCurrencyType): boolean =>
      c.isoCode === User.currencyType?.isoCode,
  );
  const [firstCurrency] = Currency.currencies;

  const {
    languageType: { code: languageCode },
    languages,
  } = Language;

  const initialValues = {
    currency: currency ? currency.isoCode : firstCurrency.isoCode,
    languageType: {
      code: languageCode,
    },
  };

  let deliveryPrice: string | undefined;
  let deliveryTime: number | undefined | null;

  if (
    Config.countryType.isoCode &&
    User.currencyType &&
    User.currencyType.isoCode &&
    Country.deliveries.length > 0
  ) {
    const delivery: IDelivery | undefined = Country.deliveries.find(
      ({ currencyType, countryType }: IDelivery): boolean =>
        currencyType.isoCode === User.currencyType?.isoCode &&
        countryType.isoCode === Config.countryType.isoCode,
    );

    if (delivery) {
      deliveryPrice = currencyFormat(delivery.price, User.currencyType);
    }

    const country: ICountry | undefined = Country.countries.find(
      ({ isoCode }: ICountry): boolean =>
        isoCode === Config.countryType.isoCode,
    );

    if (country) {
      deliveryTime = country.delivery.time;
    }
  }

  const placeholderText: string = t('layout.searchQuery');

  return (
    <div className="topbar">
      <Link to={`/${languageCode.toLowerCase()}`} className="logo-container">
        <RopereLogo type="small" />
        <span>ropere</span>
      </Link>

      <a href="mailto:info@ropere.com" className="item email">
        <svg className="mailOutlined">
          <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z" />
        </svg>
        <span className="mailOutlined--text">info@ropere.com</span>
      </a>

      <input
        type="text"
        className="searchInput"
        name="searchInput"
        placeholder={placeholderText}
        onKeyUp={(e): void => {
          handleSearchQueryChange(e.currentTarget.value);

          dispatch(
            eventSend({
              type: EEventType.SEARCH_PRODUCT,
              action: EEventContextAction.SEARCH,
              context: { search: { query: e.currentTarget.value } },
            }),
          );
        }}
      />

      <Link to={`/${languageCode.toLowerCase()}/basket`} className="item">
        <svg className="shoppingCartOutlined">
          <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
        <div className={`badge ${Basket.products.length > 0 ? '' : 'empty'}`}>
          {Basket.products.length}
        </div>
      </Link>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(): void => {}}
      >
        {(): JSX.Element => (
          <Form>
            <div className="select">
              <label htmlFor="currency-simple">
                <Trans>layout.currency</Trans>
              </label>
              <Field
                as="select"
                name="currency"
                id="currency-simple"
                onChange={({ target }): void => {
                  const ct: ICustomerCurrencyType | undefined =
                    Currency.currencies.find(
                      (c: ICustomerCurrencyType): boolean =>
                        c.isoCode === target.value,
                    );

                  if (ct) {
                    dispatch(
                      changeCurrencyType({
                        currencyType: ct,
                      }),
                    );
                  }

                  dispatch(
                    eventSend({
                      type: EEventType.CURRENCY_TYPE_CHANGE,
                      action: EEventContextAction.CHANGE,
                      context: { currencyType: { isoCode: target.value } },
                    }),
                  );
                }}
              >
                {Currency.currencies.map(
                  (c: ICustomerCurrencyType): JSX.Element => (
                    <option value={c.isoCode} key={c.isoCode}>
                      {`${c.name} (${c.symbol})`}
                    </option>
                  ),
                )}
              </Field>
            </div>

            <div className="select">
              <label htmlFor="language-simple">
                <Trans>layout.language</Trans>
              </label>
              <Field
                as="select"
                name="languageType.code"
                id="language-simple"
                onChange={({ target }): void => {
                  const code: string = target.value;

                  const lt: ILanguageType | undefined = Language.languages.find(
                    (l: ILanguageType): boolean => l.code === code,
                  );

                  if (lt) {
                    dispatch(
                      changeLanguageType({
                        languageType: lt,
                      }),
                    );
                  }

                  changeLanguage(code);

                  let fullPath: string = `/${code.toLowerCase()}`;

                  if (['p', 'product'].includes(path)) {
                    const slug: ITranslation | undefined =
                      Product.product?.slugs.find(
                        (s: ITranslation): boolean => s.code === code,
                      );

                    if (slug?.value) {
                      fullPath += `/p/${slug.value}`;
                    } else {
                      fullPath += `/product/${Product.product?.uuid}`;
                    }
                  } else {
                    if (path) {
                      fullPath += `/${path}`;
                    }

                    if (identifier) {
                      fullPath += `/${identifier}`;
                    }
                  }

                  window.history.pushState('', '', fullPath);

                  dispatch(
                    eventSend({
                      type: EEventType.LANGUAGE_TYPE_CHANGE,
                      action: EEventContextAction.CHANGE,
                      context: { languageType: { code: target.value } },
                    }),
                  );
                }}
              >
                {languages.map(
                  (l: ILanguageType): JSX.Element => (
                    <option value={l.code} key={l.code}>
                      {`${l.name} (${l.code})`}
                    </option>
                  ),
                )}
              </Field>
            </div>
          </Form>
        )}
      </Formik>

      <div className="delivery-info-banner">
        {Config.countryType.isoCode &&
          deliveryPrice &&
          deliveryTime &&
          t('layout.deliveryInfoBanner', {
            flag: Config.countryType.flag,
            deliveryPrice,
            deliveryTime,
          })}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  Basket,
  Currency,
  Language,
  User,
  Product,
  Config,
  Country,
}) => ({
  Basket,
  Currency,
  Language,
  User,
  Product,
  Config,
  Country,
});

export default connect(mapStateToProps)(Topbar);
