import {
  type ILanguageType,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { type ILanguageState } from 'customer/react/reducers/language/types';
import { type IProductState } from 'customer/react/reducers/product/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setLanguage } from '../../reducers/language/reducer';

function LanguageMutationLinks({
  Language,
  Product,
  dispatch,
}: {
  Language: ILanguageState;
  Product: IProductState;
  dispatch: Dispatch;
}): JSX.Element {
  const { i18n, t } = useTranslation();

  const changeLanguage = (code: string): void => {
    const languageType: ILanguageType | undefined = Language.languages.find(
      (language: ILanguageType): boolean => language.code === code,
    );

    if (!languageType) {
      return;
    }

    dispatch(setLanguage({ languageType }));
    i18n.changeLanguage(languageType.code.toLowerCase());
  };

  const links: JSX.Element[] = Language.languages.map(
    ({ code }: ILanguageType): JSX.Element => {
      const productName: ITranslation | undefined = Product.product?.names.find(
        (name: ITranslation): boolean => name.code === code,
      );

      let text: string = `(${code})`;

      if (productName) {
        text = `${text} ${productName.value}`;
      }

      let productLink: string = '';

      const slug: ITranslation | undefined = Product.product?.slugs.find(
        (s: ITranslation): boolean => s.code === code,
      );

      if (slug && slug.value) {
        productLink = `/${code.toLowerCase()}/p/${slug.value}`;
      } else {
        productLink = `/${code.toLowerCase()}/product/${Product.product?.uuid}`;
      }

      return (
        <Link
          key={code}
          to={productLink}
          hrefLang={code.toLowerCase()}
          onClick={(): void => changeLanguage(code)}
        >
          {text}
        </Link>
      );
    },
  );

  return (
    <div className="product container language-mutations">
      <h2>{t('layout.language')}</h2>
      <small>{links}</small>
    </div>
  );
}

const mapStateToProps = ({ Language, Product }) => ({ Language, Product });

export default connect(mapStateToProps)(LanguageMutationLinks);
