import { createSlice } from '@reduxjs/toolkit';

import {
  ClientInformation,
  type IConfigState,
  type ShowSidebar,
  Tick,
  type ToggleSidebar,
} from './types';

const initialState: IConfigState = {
  cdn: '',
  url: '',
  showSidebar: false,
  countryType: {
    isoCode: undefined,
    flag: undefined,
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    showSidebarAction: (state: IConfigState, action: ShowSidebar): void => {
      state.showSidebar = action.payload.showSidebar;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleSidebar: (state: IConfigState, _action: ToggleSidebar): void => {
      state.showSidebar = !state.showSidebar;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tick: (_state: IConfigState, _action: Tick): void => {},

    clientInformation: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _state: IConfigState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: ClientInformation,
    ): void => {},
  },
});

export const { showSidebarAction, clientInformation, toggleSidebar, tick } =
  configSlice.actions;

export default configSlice.reducer;
