import { createSlice } from '@reduxjs/toolkit';

import { type IReviewsState } from './types';

export const initialState: IReviewsState = {
  reviews: undefined,
};

const reviewsSlice = createSlice({
  name: 'Reviews',
  initialState,
  reducers: {
    loadAllReviews: (state): void => {
      state.reviews = undefined;
    },
    loadAllReviewsResponse: (state, action): void => {
      state.reviews = action.payload.reviews;
    },
  },
});

export const { loadAllReviews, loadAllReviewsResponse } = reviewsSlice.actions;

export default reviewsSlice.reducer;
