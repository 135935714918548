import { combineReducers } from '@reduxjs/toolkit';
import { withReduxStateSync } from 'redux-state-sync';

import Basket from './basket/reducer';
import Category from './category/reducer';
import Config from './config/reducer';
import Country from './country/reducer';
import Currency from './currency/reducer';
import Language from './language/reducer';
import Messenger from './messenger/reducer';
import Order from './order/reducer';
import Product from './product/reducer';
import Products from './products/reducer';
import Review from './review/reducer';
import Reviews from './reviews/reducer';
import Search from './search/reducer';
import User from './user/reducer';

export interface RootInterface {
  basket: typeof Basket;
  category: typeof Category;
  config: typeof Config;
  country: typeof Country;
  currency: typeof Currency;
  language: typeof Language;
  messenger: typeof Messenger;
  order: typeof Order;
  product: typeof Product;
  products: typeof Products;
  review: typeof Review;
  reviews: typeof Reviews;
  search: typeof Search;
  user: typeof User;
}

const combinedReducers = combineReducers({
  Basket,
  Category,
  Config,
  Country,
  Currency,
  Language,
  Messenger,
  Order,
  Product,
  Products,
  Review,
  Reviews,
  Search,
  User,
});

const rootReducer = withReduxStateSync(combinedReducers);

export type RootState = ReturnType<typeof combinedReducers>;

export default rootReducer;
