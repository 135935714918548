import { createSlice } from '@reduxjs/toolkit';

import { type ILanguageState, type SetLanguage } from './types';

const initialState: ILanguageState = {
  languages: [],
  languageType: {
    code: '',
    name: '',
  },
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state: ILanguageState, action: SetLanguage): void => {
      state.languageType = action.payload.languageType;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
