import {
  type ICategory,
  type ILanguageType,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { type ILanguageState } from 'customer/react/reducers/language/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setLanguage } from '../../reducers/language/reducer';

function LanguageMutationLinks({
  category,
  Language,
  dispatch,
}: {
  category: ICategory;
  Language: ILanguageState;
  dispatch: Dispatch;
}): JSX.Element {
  const { i18n, t } = useTranslation();

  const changeLanguage = (code: string): void => {
    const languageType: ILanguageType | undefined = Language.languages.find(
      (language: ILanguageType): boolean => language.code === code,
    );

    if (!languageType) {
      return;
    }

    dispatch(setLanguage({ languageType }));
    i18n.changeLanguage(languageType.code.toLowerCase());
  };

  const links: JSX.Element[] = Language.languages.map(
    ({ code }: ILanguageType): JSX.Element => {
      const categoryName: ITranslation | undefined = category.names.find(
        (name: ITranslation): boolean => name.code === code,
      );

      let text: string = `(${code})`;

      if (categoryName) {
        text = `${text} ${categoryName.value}`;
      }

      return (
        <Link
          key={code}
          to={`/${code.toLowerCase()}/category/${category.uuid}`}
          hrefLang={code.toLowerCase()}
          onClick={() => changeLanguage(code)}
        >
          {text}
        </Link>
      );
    },
  );

  return (
    <div className="products container language-mutations">
      <h2>{t('layout.language')}</h2>
      {links}
    </div>
  );
}

const mapStateToProps = ({ Language, Category }) => ({ Language, Category });

export default connect(mapStateToProps)(LanguageMutationLinks);
