import { type Action } from '@reduxjs/toolkit';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { Socket } from 'socket.io-client';

export interface IAction {
  type: string;
}

const sendRaw = <ACTION extends IAction, RESPONSE>(
  socket: Socket,
  action: ACTION,
): Promise<void | RESPONSE> =>
  new Promise((resolve): void => {
    socket.emit(action.type, action, resolve);
  });

function* send<ACTION extends IAction>(
  socket: Socket,
  action: ACTION,
): Generator<CallEffect | PutEffect, void, Action> {
  const response: Action = yield call(sendRaw, socket, action);

  yield put(response);
}

export { send, sendRaw };
