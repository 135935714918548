import {
  EEventContextAction,
  EEventType,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getHead } from '../../head';
import { eventSend } from '../reducers/event/reducer';
import { setActiveCategory } from '../reducers/products/reducer';

function HomeContainer({ dispatch }: { dispatch: Dispatch }): JSX.Element {
  useEffect((): (() => void) => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_HOME,
        action: EEventContextAction.ENTER,
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_HOME,
          action: EEventContextAction.LEAVE,
        }),
      );
    };
  }, [dispatch]);

  const { t } = useTranslation();

  useEffect((): void => {
    const { title } = getHead(t, t('navigation.title.home'));
    document.title = title;
  }, [t]);

  return (
    <>
      <h1>{t('home.welcome')}</h1>
      <p>{t('home.welcomeDescription')}</p>
      <h2>{t('layout.weShipGoods')}</h2>
      <p>{t('home.deliveryDescription')}</p>
    </>
  );
}

export default connect()(HomeContainer);
