import { type ITranslation } from '@mahawi/eshop-common/dist/src/types';
import { type ILanguageState } from 'customer/react/reducers/language/types';
import { type IProductState } from 'customer/react/reducers/product/types';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { connect } from 'react-redux';

function Description({
  Product,
  Language,
}: {
  Product: IProductState;
  Language: ILanguageState;
}): JSX.Element {
  let description: ITranslation | undefined =
    Product.product?.descriptions.find(
      (n: ITranslation): boolean => n.code === Language.languageType.code,
    );

  if (!description) {
    description = Product.product?.descriptions.find(
      (n: ITranslation): boolean => n.code === 'EN',
    );
  }

  return (
    <div className="product description container--7 container--t">
      <Markdown className="container margin--1">
        {description?.value || ''}
      </Markdown>
    </div>
  );
}

const mapStateToProps = ({ Product, Language }) => ({ Product, Language });

export default connect(mapStateToProps)(Description);
