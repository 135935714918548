import {
  type IPhotoDimension,
  type IProductList,
  type IReviewDescription,
  type IReviewProduct,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { type IConfigState } from '../../reducers/config/types';
import { type ILanguageState } from '../../reducers/language/types';
import { submitReview } from '../../reducers/review/reducer';
import { type IReviewState } from '../../reducers/review/types';
import { Add } from '../icons/add';
import { Remove } from '../icons/remove';
import Stars from './stars';

const PHOTO_WIDTH = 300;

function ReviewStepThree({
  Config,
  dispatch,
  Language,
  Review,
  setReviewStep,
}: {
  Config: IConfigState;
  dispatch: Dispatch;
  Language: ILanguageState;
  Review: IReviewState;
  setReviewStep: (step: number) => void;
}): JSX.Element {
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>('');
  const [prosCons, setProsCons] = useState<JSX.Element | null>(null);
  const [products, setProducts] = useState<JSX.Element | null>(null);

  useEffect((): void => {
    const descriptionUE: IReviewDescription | undefined =
      Review.review?.descriptions.find(
        ({ languageType }: IReviewDescription): boolean =>
          languageType.code === Language.languageType.code,
      );

    setDescription(descriptionUE?.description || '');

    const prosUE: IReviewDescription | undefined =
      Review.review?.descriptions.find(
        ({ languageType }: IReviewDescription): boolean =>
          languageType.code === Language.languageType.code,
      );

    const prosCount: number = prosUE?.prosCount || 0;
    const consCount: number = prosUE?.consCount || 0;

    const prosEls: JSX.Element[] = [];
    const consEls: JSX.Element[] = [];

    for (let i: number = 0; i < prosCount; i += 1) {
      if (prosUE && prosUE.pros && prosUE.pros[i]) {
        prosEls.push(
          <div
            key={i}
            className="review review--pros-cons-list review--pros-icon"
          >
            <Add />
            <span>{prosUE?.pros[i]}</span>
          </div>,
        );
      }
    }

    for (let i: number = 0; i < consCount; i += 1) {
      if (prosUE && prosUE.cons && prosUE.cons[i]) {
        consEls.push(
          <div
            key={i}
            className="review review--pros-cons-list review--cons-icon"
          >
            <Remove />
            <span>{prosUE?.cons[i]}</span>
          </div>,
        );
      }
    }

    if (prosEls.length === 0 && consEls.length === 0) {
      setProsCons(null);
    } else {
      setProsCons(
        <div className="container">
          <div className="container--5">
            <h4>{t('review.pros')}</h4>
            {prosEls}
          </div>
          <div className="container--5">
            <h4>{t('review.cons')}</h4>
            {consEls}
          </div>
        </div>,
      );
    }

    const productsEls: JSX.Element[] = [];

    Review.review?.products.forEach((product: IReviewProduct): void => {
      const productDescription: IReviewDescription | undefined =
        product.descriptions.find(
          ({ languageType }: IReviewDescription): boolean =>
            languageType.code === Language.languageType.code,
        );

      const productList: IProductList | undefined = Review.products?.find(
        (p: IProductList): IProductList | null =>
          p.uuid === product.product.uuid ? p : null,
      );

      const productName: ITranslation | undefined = productList?.names.find(
        ({ code }: ITranslation): boolean =>
          code === Language.languageType.code,
      );

      const [photo] = productList?.photos || [];
      let photoHeight: number = PHOTO_WIDTH;

      if (photo) {
        photoHeight =
          photo.dimension?.find(
            (photoDimension: IPhotoDimension): boolean =>
              photoDimension.width === PHOTO_WIDTH,
          )?.height || PHOTO_WIDTH;
      }

      const productProsCount: number = productDescription?.prosCount || 0;
      const productConsCount: number = productDescription?.consCount || 0;

      const productProsEls: JSX.Element[] = [];
      const productConsEls: JSX.Element[] = [];

      for (let i: number = 0; i < productProsCount; i += 1) {
        if (
          productDescription &&
          productDescription.pros &&
          productDescription.pros[i]
        ) {
          productProsEls.push(
            <div
              key={i}
              className="review review--pros-cons-list review--pros-icon"
            >
              <Add />
              <span>{productDescription?.pros[i]}</span>
            </div>,
          );
        }
      }

      for (let i: number = 0; i < productConsCount; i += 1) {
        if (
          productDescription &&
          productDescription.cons &&
          productDescription.cons[i]
        ) {
          productConsEls.push(
            <div
              key={i}
              className="review review--pros-cons-list review--cons-icon"
            >
              <Remove />
              <span>{productDescription?.cons[i]}</span>
            </div>,
          );
        }
      }

      productsEls.push(
        <div key={product.product.uuid} className="review review--product">
          <h4>{productName?.value || ''}</h4>
          <img
            alt={productName?.value}
            title={productName?.value}
            src={`${Config.cdn}/static/product/photo/${photo.uuid}--${PHOTO_WIDTH}.webp`}
            loading="lazy"
            width={PHOTO_WIDTH}
            height={photoHeight}
            className="review review--image"
          />
          <Stars
            fieldName="productRating"
            initialRating={product.rating || 0}
            isDisabled
          />

          {productDescription?.description?.length && (
            <>
              <h5>{t('review.resumeProduct')}</h5>
              <p>{productDescription?.description}</p>
            </>
          )}

          {(productProsEls.length || productConsEls.length) > 0 && (
            <>
              <div className="container--5">
                <h6>{t('review.pros')}</h6>
                {productProsEls}
              </div>

              <div className="container--5">
                <h6>{t('review.cons')}</h6>
                {productConsEls}
              </div>
            </>
          )}
        </div>,
      );
    });

    setProducts(
      <div className="container">
        <h3>{t('review.resumeProducts')}</h3>
        {productsEls}
      </div>,
    );
  }, [
    Config.cdn,
    Review.products,
    Review.review?.products,
    Review.review?.descriptions,
    Language.languageType.code,
    t,
  ]);

  return (
    <>
      <p>{t('review.resumeIntro')}</p>

      <p>
        {t('review.resumeLanguage', {
          languageType: Review.review?.languageType,
        })}
      </p>

      <p>{t('review.resumeName', { name: Review.review?.name })}</p>

      <Stars
        fieldName="rating"
        initialRating={Review.review?.rating || 0}
        isDisabled
      />

      {description && (
        <>
          <h3>{t('review.resumeDescription')}</h3>
          <p>{description}</p>
        </>
      )}

      {prosCons && (
        <>
          <h3>{t('review.resumeProsCons')}</h3>
          {prosCons}
        </>
      )}

      {products}

      <br />
      <br />
      <button
        className="button--primary button--centered"
        type="button"
        onClick={(): void => {
          if (Review.review) {
            dispatch(submitReview({ review: Review.review }));
          }

          setReviewStep(4);
        }}
      >
        {t('review.submit')}
      </button>

      <br />
      <br />
      <button
        className="button--primary button--half"
        onClick={(): void => setReviewStep(2)}
        type="button"
      >
        {t('review.goToPreviousPage')}
      </button>
    </>
  );
}

const mapStateToProps = ({
  Review,
  Language,
  Config,
}: {
  Review: IReviewState;
  Language: ILanguageState;
  Config: IConfigState;
}) => ({
  Review,
  Language,
  Config,
});
export default connect(mapStateToProps)(ReviewStepThree);
