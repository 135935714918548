import React from 'react';
import { useTranslation } from 'react-i18next';

function ReviewApproved(): JSX.Element {
  const { t } = useTranslation();

  return <p>{t('review.approved')}</p>;
}

export default ReviewApproved;
