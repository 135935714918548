import { createSlice } from '@reduxjs/toolkit';

import { type ICategoryState } from './types';

export const initialState: ICategoryState = {
  categories: [],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
});

export default categoriesSlice.reducer;
