import { type TFunction } from 'i18next';

import { type IHead } from './types';

export const getHead = (
  t: TFunction<'translation', undefined>,
  name: string,
  page?: number | null,
): IHead => {
  const title = `Ropere - ${name} ${
    page ? `(${t('navigation.title.page')} ${page}) ` : ``
  }| ${t('navigation.title.name')}`;

  const description = `${name} ${
    page ? `(${t('navigation.title.page')} ${page}) ` : ``
  }${t('navigation.title.description')}`;

  return { title, description };
};
