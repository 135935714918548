import { createSlice } from '@reduxjs/toolkit';

import {
  type ICountryState,
  type InitDelivery,
  type InitDeliveryResponse,
} from './types';

const initialState: ICountryState = {
  countries: [],
  deliveries: [],
};

const countryReducer = createSlice({
  name: 'Country',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initDelivery: (_state: ICountryState, _action: InitDelivery): void => {},

    initDeliveryResponse: (
      state: ICountryState,
      action: InitDeliveryResponse,
    ): void => {
      state.deliveries = action.payload.deliveries;
    },
  },
});

export const { initDelivery, initDeliveryResponse } = countryReducer.actions;

export default countryReducer.reducer;
