import React from 'react';

import { Remove } from '../icons/remove';

interface ButtonRemoveProps {
  onClick: () => void;
  text: string;
  isShown: boolean;
}

export function ButtonRemove({
  onClick,
  text,
  isShown,
}: ButtonRemoveProps): JSX.Element {
  return (
    <button
      className={`button--primary button--svg button--remove${isShown ? '' : ' button--hidden'}`}
      onClick={onClick}
      type="button"
      disabled={!isShown}
    >
      <Remove />

      <span>{text}</span>
    </button>
  );
}
