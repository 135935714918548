import {
  configureStore,
  type EnhancedStore,
  type StoreEnhancer,
  type ThunkDispatch,
  type ThunkMiddleware,
  type Tuple,
  type UnknownAction,
} from '@reduxjs/toolkit';
import {
  browserTracingIntegration,
  captureMessage,
  init,
  replayIntegration,
} from '@sentry/react';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createSagaMiddleware, { type SagaMiddleware } from 'redux-saga';

import pckg from '../../package.json';
import i18n from '../i18next-react';
import ScrollToTop from './components/scroll-to-top';
import Layout from './containers/layout';
import rootReducer, { type RootState } from './reducers/customer';
import { getSocket, mySaga } from './saga';
import Socket from './socket';

if (window.location.hostname !== 'localhost') {
  init({
    dsn: 'https://886bc2c2f07a4b43a2746e1304ed702e@sentry.ropere.com/12',
    integrations: [browserTracingIntegration(), replayIntegration()],
    tracesSampleRate: 1.0,
    release: pckg.version,
  });

  captureMessage('customer');

  const tagManagerArgs = {
    gtmId: 'GTM-MC6XVPF',
  };

  TagManager.initialize(tagManagerArgs);
}

const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware();

const preloadedState: string | undefined = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store: EnhancedStore<
  RootState,
  UnknownAction,
  Tuple<
    [
      StoreEnhancer<{
        dispatch: ThunkDispatch<RootState, undefined, UnknownAction>;
      }>,
      StoreEnhancer,
    ]
  >
> = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (
    getDefaultMiddleware,
  ): Tuple<
    [ThunkMiddleware<RootState, UnknownAction>, SagaMiddleware<object>]
  > => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(mySaga);

const socket = new Socket(getSocket(), store);
socket.requests();
socket.subscribe();

const { code } = store.getState().Language.languageType;

const customerElemenet: HTMLElement | null =
  document.getElementById('customer');

if (!customerElemenet) {
  throw new Error('Customer element not found!');
}

i18n.changeLanguage(code.toLowerCase()).then((): void => {
  hydrateRoot(
    customerElemenet,
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ScrollToTop />
          <Layout TagManager={TagManager} />
        </I18nextProvider>
      </Provider>
    </BrowserRouter>,
  );
});
