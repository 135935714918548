import { currencyFormat } from '@mahawi/eshop-common/dist/src/currency-format';
import { round } from '@mahawi/eshop-common/dist/src/round';
import {
  EEventContextAction,
  EEventType,
  type ICustomerCurrencyType,
  type IOrderProduct,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getHead } from '../../head';
import { type ICurrencyState } from '../reducers/currency/types';
import { eventSend } from '../reducers/event/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { type IOrderState } from '../reducers/order/types';
import { load } from '../reducers/product/reducer';
import { setActiveCategory } from '../reducers/products/reducer';

function OrderContainer({
  Order,
  Currency,
  Language,
  dispatch,
}: {
  Order: IOrderState;
  Currency: ICurrencyState;
  Language: ILanguageState;
  dispatch: Dispatch;
}): JSX.Element {
  useEffect((): (() => void) => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_ORDER,
        action: EEventContextAction.ENTER,
        context: { order: { uuid: Order.uuid } },
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_ORDER,
          action: EEventContextAction.LEAVE,
          context: { order: { uuid: Order.uuid } },
        }),
      );
    };
  }, [dispatch]);

  const { t } = useTranslation();

  const {
    languageType: { code: languageCode },
  } = Language;

  const languageCodeLower: string = languageCode.toLowerCase();

  useEffect((): void => {
    const { title } = getHead(t, t('navigation.title.order'));
    document.title = title;
  }, [t]);

  const currency: ICustomerCurrencyType | undefined = Currency.currencies.find(
    (c: ICustomerCurrencyType): boolean =>
      c.isoCode === Order.currencyType?.isoCode,
  );

  const totalPriceText: string = Order.price
    ? `${t('order.totalPrice')} ${currencyFormat(Order.price, currency)}.`
    : '';

  return (
    <div className="order container">
      <h1>{t('order.title', { identifier: Order.uuid })}</h1>

      <h2>{t('order.status', { status: Order.orderStatusCode })}</h2>

      <table className="orderTable">
        <thead>
          <tr>
            <th>{t('basket.productName')}</th>
            <th>{t('basket.count')}</th>
            <th>{t('basket.pricePerUnit')}</th>
            <th>{t('basket.totalPrice')}</th>
            <th>{t('basket.totalWeight')}</th>
          </tr>
        </thead>
        <tbody>
          {Order.products &&
            Order.products.map((product: IOrderProduct): JSX.Element => {
              const slug: ITranslation | undefined = product.slugs.find(
                ({ code }: ITranslation): boolean => code === languageCode,
              );

              let link: string = '';

              if (slug?.value) {
                link = `/${languageCodeLower}/p/${slug.value}`;
              } else {
                link = `/${languageCodeLower}/product/${product.uuid}`;
              }

              return (
                <tr key={product.uuid}>
                  <td>
                    <Link to={link}>
                      <button
                        type="button"
                        onClick={(): void => {
                          dispatch(
                            load({
                              product: {
                                uuid: product.uuid,
                              },
                            }),
                          );
                        }}
                      >
                        {product.names.find(
                          (n: ITranslation): boolean => n.code === languageCode,
                        )?.value || ''}
                      </button>
                    </Link>
                  </td>

                  <td>{product.count}</td>

                  <td>{currencyFormat(product.price, currency)}</td>

                  <td>
                    {currency
                      ? currencyFormat(product.price * product.count, currency)
                      : round(product.price * product.count, 2)}
                  </td>

                  <td>{`${product.goodsWeight} kg`}</td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <p>
        {t('order.totalPriceForGoodsWas', {
          price: currencyFormat(Order.price - Order.deliveryFee, currency),
        })}
      </p>

      {/* <p>
        {t('order.totalDeliveryFee', {
          totalDeliveryFee: currencyFormat(Order.deliveryFee, currency),
          packagesCount: Order.packagesCount,
        })}
      </p> */}

      <p>{totalPriceText}</p>

      {Order.discount.value > 0 &&
        Order.discount.discountType.code === 'DISCOUNT_TYPE_PERCENTAGE' &&
        `${t('order.discount.percentage', {
          discount: {
            value: Order.discount.value,
            code: Order.discount.code,
          },
        })}`}
    </div>
  );
}

const mapStateToProps = ({ Currency, Language, Order }) => ({
  Currency,
  Language,
  Order,
});

export default connect(mapStateToProps)(OrderContainer);
