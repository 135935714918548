import { createSlice } from '@reduxjs/toolkit';

import { IEventState } from './types';

const initialState: IEventState = {
  updatedAt: undefined,
};

const eventSlice = createSlice({
  name: 'Event',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    eventSend: (state: IEventState, _action): void => {
      state.updatedAt = new Date().getTime();
    },
  },
});

export const { eventSend } = eventSlice.actions;

export default eventSlice.reducer;
