import {
  EEventContextAction,
  EEventType,
  type IPhoto,
  type IPhotoDimension,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';

import { eventSend } from '../reducers/event/reducer';

function Photos({
  photos,
  cdn,
  name,
  dispatch,
}: {
  photos: IPhoto[];
  cdn: string;
  name: string;
  dispatch: Dispatch;
}): JSX.Element {
  const [originalImageUUID, setOriginalImageUUID] = useState<string>('');
  const [isModalOpened, setModalOpen] = useState<boolean>(false);

  const setModalState = useCallback(
    (isOpen: boolean): void => {
      setModalOpen(isOpen);

      dispatch(
        eventSend({
          type: EEventType.PRODUCT_PHOTO,
          action: EEventContextAction.CLICK,
          context: { isEnabled: isOpen },
        }),
      );
    },
    [dispatch],
  );

  const handleEsc = useCallback(
    (event: KeyboardEvent): void => {
      if (event.code === 'Escape') {
        setModalState(false);
      }
    },
    [setModalState],
  );

  const handleKeyDownModal = useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.code === 'ArrowRight') {
        const index: number = photos.findIndex(
          ({ uuid }: IPhoto): boolean => uuid === originalImageUUID,
        );

        if (index + 1 < photos.length) {
          setOriginalImageUUID(photos[index + 1].uuid);
        }
      }

      if (event.code === 'ArrowLeft') {
        const index: number = photos.findIndex(
          ({ uuid }: IPhoto): boolean => uuid === originalImageUUID,
        );

        if (index - 1 >= 0) {
          setOriginalImageUUID(photos[index - 1].uuid);
        }
      }
    },
    [photos, originalImageUUID],
  );

  useEffect((): (() => void) => {
    window.addEventListener('keydown', handleEsc);

    return (): void => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [setModalState, handleEsc]);

  let dimensionThumbnail: IPhotoDimension | undefined;
  let dimensionSize: IPhotoDimension | undefined;

  const images = photos.map((photo: IPhoto, index: number) => {
    const { dimension } = photo;

    if (dimension && typeof dimension !== 'string') {
      dimensionThumbnail = dimension.find(
        ({ type }: IPhotoDimension): boolean => type === '100',
      );
      dimensionSize = dimension.find(
        ({ type }: IPhotoDimension): boolean => type === '200',
      );
    }

    const srcSet: string[] = [1200, 600, 400, 300, 200, 100].map(
      (size: number): string =>
        `${cdn}/static/product/photo/${photo.uuid}--${size}.webp ${size}w`,
    );

    return {
      uuid: photo.uuid,
      original: `${cdn}/static/product/photo/${photo.uuid}--200.webp`,
      thumbnail: `${cdn}/static/product/photo/${photo.uuid}--100.webp`,
      originalAlt: `${name} (${index + 1} of ${photos.length})`,
      thumbnailAlt: `${name} thumbnail (${index + 1} of ${photos.length})`,
      originalTitle: name,
      thumbnailTitle: `${name} thumbnail`,
      srcSet: srcSet.join(','),
      dimensionThumbnail,
      dimensionSize,
    };
  });

  if (originalImageUUID === '' && photos.length > 0) {
    setOriginalImageUUID(photos[0].uuid);
  }

  let image = images.find(({ uuid }) => uuid === originalImageUUID);

  if (!image && images.length > 0) {
    [image] = images;
  }

  if (!image) {
    return <div className="photos container--7 container--t" />;
  }

  return (
    <div
      className="photos container--7 container--t"
      role="presentation"
      onKeyDown={(e): void => handleKeyDownModal(e)}
    >
      {images.length > 1 && (
        <div className="container--3 container--t">
          <div className="container--thumbnail">
            {images.map((i) => (
              <button
                type="button"
                key={i.uuid}
                className="photos--image--container"
              >
                <input
                  type="image"
                  src={i.thumbnail}
                  className="photos--thumbnail"
                  alt={i.thumbnailAlt}
                  key={i.uuid}
                  onClick={(): void => setOriginalImageUUID(i.uuid)}
                  onKeyDown={(): void => setOriginalImageUUID(i.uuid)}
                  width={i.dimensionThumbnail?.width || undefined}
                  height={i.dimensionThumbnail?.height || undefined}
                />
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="container--7 container--t main-image">
        <button type="button" onClick={(): void => setModalState(true)}>
          <img
            src={image.original}
            srcSet={image.srcSet}
            className="product-image"
            alt={image.originalAlt}
            title={image.originalTitle}
            width={image.dimensionSize?.width || undefined}
            height={image.dimensionSize?.height || undefined}
            loading="lazy"
          />
        </button>
      </div>

      <div className={`modal ${isModalOpened ? 'open' : 'close'}`}>
        <button
          type="button"
          className={`photos--closeIcon ${isModalOpened ? 'opened' : 'closed'}`}
          onClick={(): void => setModalState(false)}
          onKeyDown={(): void => setModalState(false)}
          aria-label="Close"
        >
          <svg>
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
          </svg>
        </button>

        <div className="container--image">
          <img
            src={image.original}
            srcSet={image.srcSet}
            className="image"
            alt={image.originalAlt}
            title={image.originalTitle}
            width={image.dimensionSize?.width || undefined}
            height={image.dimensionSize?.height || undefined}
            loading="lazy"
          />
        </div>

        <div className="container--thumbnail">
          {images.map((i) => (
            <button
              type="button"
              key={i.uuid}
              onClick={(): void => setOriginalImageUUID(i.uuid)}
              onKeyDown={(): void => setOriginalImageUUID(i.uuid)}
            >
              <img
                src={i.thumbnail}
                className={`photos--thumbnail ${
                  i.uuid === originalImageUUID ? 'active' : ''
                }`}
                alt={i.thumbnailAlt}
                title={i.thumbnailTitle}
                width={i.dimensionThumbnail?.width || undefined}
                height={i.dimensionThumbnail?.height || undefined}
                loading="lazy"
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Photos;
