import React from 'react';
import { useTranslation } from 'react-i18next';

function ReviewSubmitted(): JSX.Element {
  const { t } = useTranslation();

  return <p>{t('review.alreadySubmitted')}</p>;
}

export default ReviewSubmitted;
