import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  type IReviewDescription,
  type IReviewProduct,
} from '@mahawi/eshop-common/dist/src/types';
import { createSlice } from '@reduxjs/toolkit';

import {
  type IGetProducts,
  type IGetProductsResponse,
  type IGetReviewResponse,
  type IPutReview,
  type IPutReviewResponse,
  type IReviewState,
  type ISetFields,
  type ISubmitReview,
  type ISubmitReviewResponse,
} from './types';

export const initialState: IReviewState = {
  review: undefined,
  products: undefined,
};

const reviewSlice = createSlice({
  name: 'Review',
  initialState,
  reducers: {
    getReview: (state): void => {
      state.review = undefined;
    },
    getReviewResponse: (state, action: IGetReviewResponse): void => {
      state.review = action.payload.review;
    },
    setFields: (state, action: ISetFields): void => {
      if (state.review) {
        if (
          action.payload.description ||
          action.payload.prosCount ||
          action.payload.consCount
        ) {
          const d: IReviewDescription | undefined =
            state.review.descriptions.find(
              ({ languageType }: IReviewDescription): boolean =>
                languageType.code === action.payload.languageType.code,
            );

          if (d) {
            if (action.payload.description) {
              d.description = action.payload.description;
            }

            if (action.payload.pros) {
              d.pros = action.payload.pros.filter(notEmpty);
            }

            if (action.payload.prosCount) {
              d.prosCount = action.payload.prosCount || 0;
            }

            if (action.payload.cons) {
              d.cons = action.payload.cons.filter(notEmpty);
            }

            if (action.payload.consCount) {
              d.consCount = action.payload.consCount || 0;
            }
          }
        }

        if (action.payload.productUUID) {
          const p: IReviewProduct | undefined = state.review.products.find(
            ({ product }: IReviewProduct): boolean =>
              product.uuid === action.payload.productUUID,
          );

          if (p) {
            if (action.payload.productRating) {
              p.rating = action.payload.productRating;
            }

            if (
              action.payload.productDescription ||
              action.payload.productProsCount ||
              action.payload.productConsCount
            ) {
              const d: IReviewDescription | undefined = p.descriptions.find(
                ({ languageType }: IReviewDescription): boolean =>
                  languageType.code === action.payload.languageType.code,
              );

              if (d) {
                if (action.payload.productDescription) {
                  d.description = action.payload.productDescription;
                }

                if (action.payload.productPros) {
                  d.pros = action.payload.productPros.filter(notEmpty);
                }

                if (action.payload.productProsCount) {
                  d.prosCount = action.payload.productProsCount || 0;
                }

                if (action.payload.productCons) {
                  d.cons = action.payload.productCons.filter(notEmpty);
                }

                if (action.payload.productConsCount) {
                  d.consCount = action.payload.productConsCount || 0;
                }
              }
            }
          }
        }

        state.review.name = action.payload.name || state.review.name;
        state.review.rating = action.payload.rating || state.review.rating;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProducts: (state, _action: IGetProducts): void => {
      state.products = undefined;
    },
    getProductsResponse: (state, action: IGetProductsResponse): void => {
      state.products = action.payload.products;
    },
    putReview: (state, action: IPutReview): void => {
      state.review = action.payload.review;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    putReviewResponse: (_state, _action: IPutReviewResponse): void => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitReview: (_state, _action: ISubmitReview): void => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitReviewResponse: (_state, _action: ISubmitReviewResponse): void => {},
  },
});

export const {
  getReview,
  getReviewResponse,
  setFields,
  getProducts,
  getProductsResponse,
  putReview,
  putReviewResponse,
  submitReview,
  submitReviewResponse,
} = reviewSlice.actions;

export default reviewSlice.reducer;
