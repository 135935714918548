import {
  EEventContextAction,
  EEventType,
  type IMessage,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Field, Form, Formik } from 'formik';
import React, {
  createRef,
  type RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { type IConfigState } from '../reducers/config/types';
import { eventSend } from '../reducers/event/reducer';
import { sendMessage } from '../reducers/messenger/reducer';
import { type IMessengerState } from '../reducers/messenger/types';

function MessengerComponent({
  Config,
  dispatch,
  Messenger,
}: {
  Config: IConfigState;
  dispatch: Dispatch;
  Messenger: IMessengerState;
}): JSX.Element {
  const { t } = useTranslation();

  const [isOpened, setOpened] = useState(false);
  const [unReaded, setUnreaded] = useState(-1);
  const messageRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  const setMessengerState = useCallback(
    (isOpen: boolean): void => {
      setOpened(isOpen);
      dispatch(
        eventSend({
          type: EEventType.MESSENGER_WINDOW_OPEN,
          action: EEventContextAction.CLICK,
          context: { isEnabled: isOpen },
        }),
      );
    },
    [setOpened, dispatch],
  );

  useEffect((): void => {
    messageRef?.current?.scrollIntoView();
  });

  useEffect((): void => {
    setUnreaded((count: number): number => count + 1);
  }, [setUnreaded, Messenger.lastMessageUUID]);

  useEffect((): void => {
    if (unReaded > 0) {
      const audio = new Audio(`${Config.cdn}/static/juntos.mp3`);

      if (audio) {
        audio.play();
      }
    }
  }, [unReaded, Config.cdn]);

  return (
    <div className={`messenger ${isOpened ? 'opened' : 'closed'}`}>
      <div
        role="presentation"
        className="messenger--icon"
        onClick={(): void => setMessengerState(true)}
      >
        <svg>
          <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
        </svg>
      </div>

      <div
        role="presentation"
        className={`messenger--closeIcon ${isOpened ? 'opened' : 'closed'}`}
        onClick={(): void => {
          setMessengerState(false);
          setUnreaded(0);
        }}
      >
        <svg>
          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
        </svg>
      </div>

      <div
        role="presentation"
        className={`messenger--messageCount ${
          !isOpened && unReaded > 0 ? 'show' : 'hide'
        }`}
      >
        {unReaded}
      </div>

      <div
        role="presentation"
        className={`messenger--isAvailable ${
          Messenger.isAvailable ? 'yes' : 'no'
        }`}
      />

      <div className={`messenger--content ${isOpened ? 'opened' : 'closed'}`}>
        {Messenger.messages.map(
          ({ message, direction, uuid }: IMessage): JSX.Element => (
            <div
              key={uuid}
              className={`messenger--message ${direction}`}
              ref={messageRef}
            >
              {message}
            </div>
          ),
        )}
      </div>
      <div
        className={`messenger--write-a-message ${
          isOpened ? 'opened' : 'closed'
        }`}
      >
        <Formik
          enableReinitialize
          initialValues={{
            message: Messenger.message,
          }}
          onSubmit={({ message }): void => {
            if (!message) {
              return;
            }

            setUnreaded(0);
            dispatch(
              sendMessage({
                message,
              }),
            );
          }}
        >
          <Form>
            <Field
              placeholder={t('messenger.writeAMessage')}
              name="message"
              type="text"
              disabled={Messenger.inProgress}
              id="messenger-message"
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Messenger, Config }) => ({
  Messenger,
  Config,
});

export default connect(mapStateToProps)(MessengerComponent);
