import React from 'react';

function LinearProgress(): JSX.Element {
  return (
    <div className="linearProgress">
      <div className="linearProgress--bar-one" />
      <div className="linearProgress--bar-two" />
    </div>
  );
}

export default LinearProgress;
