import { currencyFormat } from '@mahawi/eshop-common/dist/src/currency-format';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  type ICurrencyPrice,
  type ICustomerProductDetail,
  type IPhotoDimension,
  type IProductList,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { type IConfigState } from 'customer/react/reducers/config/types';
import { type ILanguageState } from 'customer/react/reducers/language/types';
import { type IUserState } from 'customer/react/reducers/user/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { load } from '../../reducers/product/reducer';

function Grid({
  Config,
  products,
  User,
  Language,
  headerLevel,
  dispatch,
}: {
  dispatch: Dispatch;
  Config: IConfigState;
  User: IUserState;
  Language: ILanguageState;
  products: ICustomerProductDetail[] | IProductList[];
  headerLevel: number;
}): JSX.Element[] {
  const { t } = useTranslation();

  const { currencyType } = User;

  return products.map((product: ICustomerProductDetail | IProductList) => {
    const currencyPrice: ICurrencyPrice | undefined = product.prices.find(
      (c: ICurrencyPrice): boolean => c.isoCode === currencyType?.isoCode,
    );

    const [photo] = product.photos;

    let productName: ITranslation | undefined = product.names.find(
      (p: ITranslation): boolean => p.code === Language.languageType.code,
    );

    if (!productName) {
      productName = product.names.find(
        (p: ITranslation): boolean => p.code === 'EN',
      );
    }

    let srcSet: string = '';
    let dimension: IPhotoDimension | undefined;

    if (photo) {
      srcSet = [300, 200, 100]
        .map(
          (size: number): string =>
            `${Config.cdn}/static/product/photo/${photo.uuid}--${size}.webp ${size}w`,
        )
        .join(',');

      dimension = photo.dimension
        ? photo.dimension.find(
            ({ type }: IPhotoDimension): boolean => type === '100',
          )
        : undefined;
    }

    const currencyParagraph: string = currencyPrice
      ? `${currencyFormat(currencyPrice.price, currencyType)}`
      : '';

    const stockParagraph: string = product.stock
      ? t('products.countOnStock', { count: product.stock })
      : t('product.outOfStock');

    const descriptionParagraph: string = [currencyParagraph, stockParagraph]
      .filter(notEmpty)
      .join(', ');

    let productHeader: JSX.Element = (
      <h2>{productName ? productName.value : ''}</h2>
    );

    if (headerLevel === 3) {
      productHeader = <h3>{productName ? productName.value : ''}</h3>;
    }

    let productLink: string = '';

    const slug: ITranslation | undefined = product.slugs.find(
      (s: ITranslation): boolean => s.code === Language.languageType.code,
    );

    if (slug && slug.value) {
      productLink = `/${Language.languageType.code.toLowerCase()}/p/${
        slug.value
      }`;
    } else {
      productLink = `/${Language.languageType.code.toLowerCase()}/product/${
        product.uuid
      }`;
    }

    return (
      <Link to={productLink} key={product.uuid} className="products--link">
        <button
          type="button"
          onClick={(): void => {
            dispatch(
              load({
                product: {
                  uuid: product.uuid,
                },
              }),
            );
          }}
        >
          <div className="products--image--container">
            {photo && dimension && (
              <img
                alt={productName ? productName.value : ''}
                title={productName ? productName.value : ''}
                src={`${Config.cdn}/static/product/photo/${photo.uuid}--100.webp`}
                srcSet={srcSet}
                width={dimension.width}
                height={dimension.height}
                loading="lazy"
              />
            )}
          </div>

          <p>{product.brand?.name}</p>
          {productHeader}
          <p>{descriptionParagraph}</p>
        </button>
      </Link>
    );
  });
}

const mapStateToProps = ({ Config, User, Language }) => ({
  Config,
  User,
  Language,
});

export default connect(mapStateToProps)(Grid);
