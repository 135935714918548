import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  type ICategory,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { type ICategoryState } from '../reducers/category/types';
import { showSidebarAction } from '../reducers/config/reducer';
import { type IConfigState } from '../reducers/config/types';
import { type ILanguageState } from '../reducers/language/types';
import { reset } from '../reducers/product/reducer';
import { type IProductsState } from '../reducers/products/types';

function Sidebar({
  Category,
  Config,
  dispatch,
  Language,
  Products,
}: {
  Category: ICategoryState;
  Config: IConfigState;
  dispatch: Dispatch;
  Language: ILanguageState;
  Products: IProductsState;
}): JSX.Element {
  const getLink = useCallback(
    (category: ICategory, isActive = false): JSX.Element => {
      let categoryName: ITranslation | undefined = category.names.find(
        (n: ITranslation): boolean => n.code === Language.languageType.code,
      );

      if (!categoryName) {
        categoryName = category.names.find(
          (n: ITranslation): boolean => n.code === 'EN',
        );
      }

      return (
        <Link
          to={`/${Language.languageType.code.toLowerCase()}/category/${
            category.uuid
          }`}
          onClick={(): void => {
            dispatch(
              showSidebarAction({
                showSidebar: false,
              }),
            );
            dispatch(reset());
          }}
        >
          <span className={isActive ? 'isActive' : ''}>
            {categoryName?.value || '---'}
          </span>
        </Link>
      );
    },
    [Language.languageType.code, dispatch],
  );

  const activeCategoryUUID: string = Products.category.uuid;
  const sidebarElements: JSX.Element[] = [];

  Category.categories.forEach((category: ICategory): void => {
    const childCategoriesLinks: JSX.Element[] = [];

    if (category.parentCategoryUUID) {
      return;
    }

    const showChildCategories: boolean =
      activeCategoryUUID === category.uuid ||
      category.childCategoriesUUIDs.includes(activeCategoryUUID);

    if (showChildCategories) {
      const childCategories: ICategory[] = category.childCategoriesUUIDs
        .map((childCategoryUUID: string): ICategory | undefined =>
          Category.categories.find(
            (c: ICategory): boolean => c.uuid === childCategoryUUID,
          ),
        )
        .filter(notEmpty)
        .sort((a: ICategory, b: ICategory): number => a.position - b.position);

      if (childCategories.length) {
        childCategories.forEach((childCategory: ICategory): void => {
          const isActiveChild: boolean =
            activeCategoryUUID === childCategory.uuid;

          if (childCategory.brands?.length === 0) {
            return;
          }

          childCategoriesLinks.push(
            <li key={childCategory.uuid}>
              {getLink(childCategory, isActiveChild)}
            </li>,
          );
        });
      }
    }

    const isActiveParent: boolean = activeCategoryUUID === category.uuid;

    sidebarElements.push(
      <li key={category.uuid}>
        {getLink(category, isActiveParent)}
        {childCategoriesLinks.length > 0 && <ul>{childCategoriesLinks}</ul>}
      </li>,
    );
  });

  const { showSidebar } = Config;

  return (
    <div className={`sidebar ${showSidebar ? 'show' : 'hide'}`}>
      <ul>{sidebarElements}</ul>
    </div>
  );
}

const mapStateToProps = ({ Category, Config, Language, Products }) => ({
  Category,
  Config,
  Language,
  Products,
});

export default connect(mapStateToProps)(Sidebar);
