import {
  type IReviewDescription,
  type IReviewStepOneValidationErrors,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Form, Formik, type FormikProps, type FormikValues } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { type IConfigState } from '../../reducers/config/types';
import { type ILanguageState } from '../../reducers/language/types';
import { setFields } from '../../reducers/review/reducer';
import { type IReviewState } from '../../reducers/review/types';
import Input from '../input';
import { ButtonAdd } from './button-add';
import { ButtonRemove } from './button-remove';
import Stars from './stars';

const PROS_CONS_COUNT = 10;

function ReviewStepOne({
  Review,
  Language,
  dispatch,
  setReviewStep,
}: {
  Review: IReviewState;
  Language: ILanguageState;
  dispatch: Dispatch;
  setReviewStep: (step: number) => void;
}): JSX.Element {
  const formRef = useRef<FormikProps<FormikValues> | null>(null);
  const { t } = useTranslation();

  const [prosCount, setProsCount] = useState(2);
  const [consCount, setConsCount] = useState(2);
  const [prosArray, setProsArray] = useState<string[]>([]);
  const [consArray, setConsArray] = useState<string[]>([]);
  const [desc, setDesc] = useState<IReviewDescription | undefined>();

  const setField = useCallback(
    (field: string, value: string | number | string[]): void => {
      formRef.current?.setFieldValue(field, value);
    },
    [],
  );

  useEffect((): void => {
    const d: IReviewDescription | undefined = Review.review?.descriptions.find(
      ({ languageType }: IReviewDescription): boolean =>
        languageType.code === Language.languageType.code,
    );

    let pros: string[] = d?.pros || [];
    let cons: string[] = d?.cons || [];

    if (prosCount < pros.length) {
      setProsCount(pros.length);
    }

    if (consCount < cons.length) {
      setConsCount(cons.length);
    }

    pros = [
      ...pros,
      ...Array.from(Array(PROS_CONS_COUNT - pros.length).keys()).map(
        (): string => '',
      ),
    ];
    cons = [
      ...cons,
      ...Array.from(Array(PROS_CONS_COUNT - cons.length).keys()).map(
        (): string => '',
      ),
    ];

    setProsArray(pros);
    setConsArray(cons);
    setDesc(d);
  }, [
    Review.review?.descriptions,
    Language.languageType.code,
    Language.languageType.name,
  ]);

  useEffect((): void => {
    if (Review.review?.languageType) {
      dispatch(
        setFields({
          languageType: Review.review.languageType,
          prosCount,
          consCount,
        }),
      );
    }
  }, [prosCount, consCount, Review.review?.languageType, dispatch]);

  return (
    <>
      <p>{t('review.intro')}</p>
      <br />
      <p>
        {t('review.order', { orderUUID: Review.review?.order.uuid })}&nbsp;
        <Link
          to={`/${Language.languageType.code.toLowerCase()}/order/${Review.review?.order.uuid}`}
          target="_blank"
        >
          🔗
        </Link>
      </p>
      <br />
      <p>{t('review.language', { languageType: Language.languageType })}</p>
      <br />
      <p>{t('review.question')}</p>
      <br />
      <p>{t('review.stepOneDescription')}</p>
      <br />
      <br />
      <Formik
        innerRef={(formikRef) => {
          formRef.current = formikRef;
        }}
        enableReinitialize
        initialValues={{
          name: Review.review?.name || '',
          rating: Review.review?.rating || 0,
          description: desc?.description || '',
          pros: prosArray || [],
          cons: consArray || [],
        }}
        onSubmit={(_values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validate={({ name, rating, description, pros, cons }) => {
          let isValid: boolean = true;

          const errors: IReviewStepOneValidationErrors = {
            name: '',
            rating: '',
            description: '',
            pros: [],
            cons: [],
          };

          if (name.trim().length < 2) {
            errors.name = t('review.nameIsTooShort');
            isValid = false;
          }

          if (name.trim().length > 50) {
            errors.name = t('review.nameIsTooLong');
            isValid = false;
          }

          if (description.trim().length > 500) {
            errors.description = t('review.descriptionIsTooLong');
            isValid = false;
          }

          pros.forEach((pro: string, i: number): void => {
            if (pro.trim().length > 500) {
              errors.pros[i] = t('review.proIsTooLong', { i: i + 1 });
              isValid = false;
            }
          });

          cons.forEach((con: string, i: number): void => {
            if (con.trim().length > 500) {
              errors.cons[i] = t('review.conIsTooLong', { i: i + 1 });
              isValid = false;
            }
          });

          const { languageType } = Language;

          dispatch(
            setFields({
              languageType,
              name,
              rating,
              description,
              pros,
              cons,
              prosCount,
              consCount,
            }),
          );

          return isValid ? {} : errors;
        }}
      >
        {({ errors, values }) => (
          <Form>
            <Input
              errorMessage={errors?.name}
              name="name"
              label={t('review.fieldName')}
              type="text"
            />

            <Stars
              setField={setField}
              fieldName="rating"
              initialRating={values.rating}
            />

            <Input
              errorMessage={errors?.description}
              name="description"
              label={t('review.fieldDescription')}
              type="textarea"
            />

            <div className="review review--pros">
              <h3>{t('review.pros')}</h3>

              {Array.from(Array(prosCount).keys()).map(
                (i: number): JSX.Element => (
                  <Input
                    key={i}
                    errorMessage={errors?.pros && errors.pros[i]}
                    name={`pros[${i}]`}
                    label={t('review.pro', { index: i + 1 })}
                    type="text"
                  />
                ),
              )}

              <div className="container container--6 container--center">
                <ButtonAdd
                  onClick={(): void => setProsCount(prosCount + 1)}
                  text={t('review.addPros')}
                  isShown={prosCount < PROS_CONS_COUNT}
                />

                <ButtonRemove
                  onClick={(): void => setProsCount(prosCount - 1)}
                  text={t('review.removePros')}
                  isShown={prosCount > 1}
                />
              </div>
            </div>

            <div className="review review--cons">
              <h3>{t('review.cons')}</h3>

              {Array.from(Array(consCount).keys()).map(
                (i: number): JSX.Element => (
                  <Input
                    key={i}
                    errorMessage={errors?.cons && errors.cons[i]}
                    name={`cons[${i}]`}
                    label={t('review.con', { index: i + 1 })}
                    type="text"
                  />
                ),
              )}

              <div className="container container--6 container--center">
                <ButtonAdd
                  onClick={(): void => setConsCount(consCount + 1)}
                  text={t('review.addCons')}
                  isShown={consCount < PROS_CONS_COUNT}
                />

                <ButtonRemove
                  onClick={(): void => setConsCount(consCount - 1)}
                  text={t('review.removeCons')}
                  isShown={consCount > 1}
                />
              </div>
            </div>

            <br />
            <br />
            <button
              className="button--primary button--centered"
              type="button"
              onClick={(): void | boolean =>
                Object.keys(errors).length === 0 && setReviewStep(2)
              }
            >
              {t('review.goToNextPage')}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = ({
  Review,
  Language,
  Config,
}: {
  Review: IReviewState;
  Language: ILanguageState;
  Config: IConfigState;
}): {
  Review: IReviewState;
  Language: ILanguageState;
  Config: IConfigState;
} => ({
  Review,
  Language,
  Config,
});

export default connect(mapStateToProps)(ReviewStepOne);
