import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { type ILanguageState } from '../../reducers/language/types';
import { type IProductsState } from '../../reducers/products/types';

function Pagination({
  Products,
  Language,
  categoryUUID,
}: {
  Products: IProductsState;
  Language: ILanguageState;
  categoryUUID: string | undefined;
}): JSX.Element | null {
  if (!categoryUUID) {
    return null;
  }

  const pagesButtons: JSX.Element[] = [];

  if (Products.pages <= 12) {
    for (let i: number = 1; i <= Products.pages; i += 1) {
      pagesButtons.push(
        <Link
          key={i}
          type="button"
          className={i === Products.page ? 'active' : ''}
          to={`/${Language.languageType.code.toLowerCase()}/category/${categoryUUID}${
            i > 1 ? `/${i}` : ``
          }`}
        >
          {i}
        </Link>,
      );
    }
  } else {
    const prevPage: number = Products.page - 1 < 1 ? 1 : Products.page - 1;
    const prevPage2: number = Products.page - 2 < 1 ? 1 : Products.page - 2;
    const nextPage: number =
      Products.page + 1 > Products.pages ? Products.pages : Products.page + 1;
    const nextPage2: number =
      Products.page + 2 > Products.pages ? Products.pages : Products.page + 2;

    const pages: Set<number> = new Set([
      1,
      2,
      3,
      prevPage2,
      prevPage,
      Products.page,
      nextPage,
      nextPage2,
      Products.pages - 2,
      Products.pages - 1,
      Products.pages,
    ]);

    pages.forEach((pageNumber: number, index: number): void => {
      if (index === prevPage2 && Products.page === 7) {
        pagesButtons.push(
          <Link
            key={4}
            type="button"
            to={`/${Language.languageType.code.toLowerCase()}/category/${categoryUUID}`}
          >
            4
          </Link>,
        );
      } else if (index === prevPage2 && Products.page > 6) {
        pagesButtons.push(
          <Link
            key="fast-backward"
            type="button"
            to={`/${Language.languageType.code.toLowerCase()}/category/${categoryUUID}/${Products.page - 5}`}
          >
            {`<<5`}
          </Link>,
        );
      }

      pagesButtons.push(
        <Link
          key={pageNumber}
          type="button"
          className={pageNumber === Products.page ? 'active' : ''}
          to={`/${Language.languageType.code.toLowerCase()}/category/${categoryUUID}${
            pageNumber > 1 ? `/${pageNumber}` : ``
          }`}
        >
          {pageNumber}
        </Link>,
      );

      if (index === nextPage2 && Products.page === Products.pages - 6) {
        pagesButtons.push(
          <Link
            key={Products.pages - 3}
            type="button"
            to={`/${Language.languageType.code.toLowerCase()}/category/${categoryUUID}/${Products.pages - 3}`}
          >
            {Products.pages - 3}
          </Link>,
        );
      } else if (index === nextPage2 && Products.page + 5 < Products.pages) {
        pagesButtons.push(
          <Link
            key="fast-forward"
            type="button"
            to={`/${Language.languageType.code.toLowerCase()}/category/${categoryUUID}/${Products.page + 5}`}
          >
            {`5>>`}
          </Link>,
        );
      }
    });
  }

  return <div className="page--buttons">{pagesButtons}</div>;
}

const mapStateToProps = ({ Products, Language }) => ({
  Products,
  Language,
});

export default connect(mapStateToProps)(Pagination);
