import React from 'react';

function RopereLogo({ type }: { type: 'large' | 'small' }): JSX.Element {
  const className = `logo ${type}`;

  return (
    <svg className={className} x="0px" y="0px" viewBox="0 0 300 200">
      <g id="LOGO">
        <path
          className="logo purple"
          d="M125.4,130c-11.5,0-20.8-9.3-20.8-20.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8c0,9.5,7.7,17.3,17.3,17.3
      c1,0,1.8,0.8,1.8,1.8S126.4,130,125.4,130z"
        />
        <path
          className="logo purple"
          d="M183.5,130c-11.5,0-20.8-9.3-20.8-20.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8c0,9.5,7.7,17.3,17.3,17.3
      c1,0,1.8,0.8,1.8,1.8S184.4,130,183.5,130z"
        />
        <path
          className="logo purple"
          d="M154.4,180.3c-11.5,0-20.8-9.3-20.8-20.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8c0,9.5,7.7,17.3,17.3,17.3
      c1,0,1.8,0.8,1.8,1.8S155.4,180.3,154.4,180.3z"
        />
        <path
          className="logo purple"
          d="M92.8,109.2c0,17.2,13.4,31.3,30.2,32.5c1.5-2.6,3.3-5,5.4-7.2c-1,0.1-2,0.2-3,0.2
      c-14.1,0-25.5-11.4-25.5-25.5v0c0-15,13.1-27.1,28.5-25.3c12.9,1.5,22.4,12.7,22.5,25.7c0.2,18.7,16.1,33.6,35.2,32.1
      c15.9-1.2,28.8-14.2,29.9-30.1c1.3-19-13.8-34.9-32.5-34.9c-11.2,0-21.1,5.7-26.9,14.3c1.5,2.5,2.7,5.3,3.5,8.2
      c4.2-9.6,14-16.2,25.3-15.3c12.3,0.9,22.4,10.8,23.5,23.1c1.8,19.5-18.7,35.1-38.9,24.3c-7.8-4.1-12.1-12.5-12-21.3
      c0.4-18.3-14.4-33.2-32.6-33.2C107.4,76.6,92.8,91.2,92.8,109.2L92.8,109.2z"
        />
        <path
          className="logo purple"
          d="M183.5,145.3c-3,0-5.9-0.4-8.6-1c1,1.3,1.8,2.7,2.5,4.2c5.4,11.1,1.8,24.7-8.2,31.9
      c-16,11.3-39.8,1.4-40.5-21.6c-0.3-8.8,4.8-16.8,12.5-21c4.7-2.6,8.4-6.1,11.2-10.2c-1.5-2.6-2.8-5.4-3.6-8.4
      c-2.2,5-5.9,9.4-10.9,12.4c-16.1,9.5-21,30.8-10.3,46.5c9,13.2,26.7,17.9,41.1,10.9c16.4-8,22.8-27.6,15.1-43.5
      C183.6,145.3,183.5,145.3,183.5,145.3z"
        />
        <path
          className="logo green"
          d="M115,10c-1.3,5-2,10.2-2,15.6c0,26.8,17.3,49.5,41.3,57.6c1.3-5,2-10.2,2-15.6C156.2,40.9,139,18.2,115,10z"
        />
        <path
          className="logo green"
          d="M187.4,33.1c-0.1-4.6-0.9-9.1-2.1-13.3c-12.6,4.7-22.8,14.1-28.6,25.9c2.4,6.9,3.8,14.2,3.8,21.8
      c0,4.4-0.5,8.9-1.4,13.2C176.4,72,188,53.8,187.4,33.1z"
        />
      </g>
    </svg>
  );
}

export default RopereLogo;
