import {
  EEventContextAction,
  EEventType,
  EReviewStatusType,
  type IReviewProduct,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getHead } from '../../head';
import ReviewApproved from '../components/review/approved';
import ReviewRejected from '../components/review/rejected';
import ReviewStepFour from '../components/review/step-four';
import ReviewStepOne from '../components/review/step-one';
import ReviewStepThree from '../components/review/step-three';
import ReviewStepTwo from '../components/review/step-two';
import ReviewSubmitted from '../components/review/submitted';
import { eventSend } from '../reducers/event/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { setActiveCategory } from '../reducers/products/reducer';
import { getProducts, putReview } from '../reducers/review/reducer';
import { type IReviewState } from '../reducers/review/types';

function ReviewContainer({
  dispatch,
  Review,
  Language,
}: {
  dispatch: Dispatch;
  Review: IReviewState;
  Language: ILanguageState;
}): JSX.Element {
  const { t } = useTranslation();
  const [reviewStepInt, setReviewStepInt] = useState(1);
  const [content, setContent] = useState<JSX.Element>();

  const setReviewStep = useCallback(
    (step: number): void => {
      setReviewStepInt((prevState: number): number => {
        if (step > prevState && Review.review) {
          dispatch(
            putReview({
              review: Review.review,
            }),
          );
        }

        return step;
      });
    },
    [dispatch, Review.review],
  );

  useEffect((): (() => void) => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_REVIEW,
        action: EEventContextAction.ENTER,
        context: { review: { uuid: Review.review?.uuid || '' } },
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_REVIEW,
          action: EEventContextAction.LEAVE,
          context: { review: { uuid: Review.review?.uuid || '' } },
        }),
      );
    };
  }, [dispatch, Review.review?.uuid]);

  useEffect((): void => {
    const productUUIDs: string[] =
      Review.review?.products.map(
        ({ product }: IReviewProduct): string => product.uuid,
      ) || [];

    if (
      productUUIDs.length > 0 &&
      (!Review.products || !Review.products.length)
    ) {
      dispatch(
        getProducts({
          productUUIDs,
        }),
      );
    }
  }, [dispatch, Review.review?.products]);

  useEffect((): void => {
    const { title } = getHead(t, t('navigation.title.review'));
    document.title = title;
  }, [t]);

  useEffect((): void => {
    if (Review.review?.reviewStatusType.code === EReviewStatusType.SUBMITTED) {
      setContent(<ReviewSubmitted />);
    } else if (
      Review.review?.reviewStatusType.code === EReviewStatusType.APPROVED
    ) {
      setContent(<ReviewApproved />);
    } else if (
      Review.review?.reviewStatusType.code === EReviewStatusType.REJECTED
    ) {
      setContent(<ReviewRejected />);
    } else if (reviewStepInt === 1) {
      setContent(<ReviewStepOne setReviewStep={setReviewStep} />);
    } else if (reviewStepInt === 2) {
      setContent(<ReviewStepTwo setReviewStep={setReviewStep} />);
    } else if (reviewStepInt === 3) {
      setContent(<ReviewStepThree setReviewStep={setReviewStep} />);
    } else if (reviewStepInt === 4) {
      setContent(<ReviewStepFour />);
    }
  }, [
    Review.review,
    reviewStepInt,
    dispatch,
    Review.products,
    Language.languageType.code,
    setReviewStep,
  ]);

  useEffect((): void => {
    window.scrollTo(0, 0);
  }, [reviewStepInt]);

  return (
    <div className="container--5 container--t container--center review">
      <h1>{t('navigation.title.review')}</h1>
      {content}
    </div>
  );
}

const mapStateToProps = ({
  Review,
  Language,
}: {
  Review: IReviewState;
  Language: ILanguageState;
}) => ({
  Review,
  Language,
});

export default connect(mapStateToProps)(ReviewContainer);
