import { createSlice } from '@reduxjs/toolkit';

import {
  CreateOrder,
  type CreateOrderResponse,
  type IOrderState,
} from './types';

export const initialState: IOrderState = {
  uuid: '',
  price: 0,
  redirectLink: '',
  isLoading: false,
  orderStatusCode: '',
  currencyType: {
    isoCode: 'EUR',
    name: 'Euro',
    symbol: '€',
    isSymbolBeforePrice: false,
    decimalPrecision: 2,
  },
  products: [],
  packagesCount: 0,
  deliveryFee: 0,
  discount: {
    discountType: {
      code: '',
    },
    code: '',
    value: 0,
  },
};

const orderSlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    reset: (state): void => {
      state = initialState;
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    create: (_state, _action: CreateOrder): void => {},
    createResponse: (state, action: CreateOrderResponse): void => {
      state.uuid = action.payload.uuid;
      state.redirectLink = action.payload.redirectLink;
    },
  },
});

export const { reset, create, createResponse } = orderSlice.actions;

export default orderSlice.reducer;
