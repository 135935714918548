import {
  type ICategory,
  type ICustomerCategory,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { type ICategoryState } from 'customer/react/reducers/category/types';
import { type ILanguageState } from 'customer/react/reducers/language/types';
import { type IProductState } from 'customer/react/reducers/product/types';
import { type IProductsState } from 'customer/react/reducers/products/types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { reset } from '../../reducers/product/reducer';

function Breadcrumbs({
  Product,
  Language,
  Products,
  Category,
  dispatch,
}: {
  Product: IProductState;
  Language: ILanguageState;
  Products: IProductsState;
  Category: ICategoryState;
  dispatch: Dispatch;
}): JSX.Element {
  const [productElement, setProductElement] = useState<JSX.Element | null>(
    null,
  );
  const [childCategoryElement, setChildCategoryElement] =
    useState<JSX.Element | null>(null);
  const [didMount, setDidMount] = useState(false);

  const getBreadcrumbElement = useCallback(
    (categories: ICategory[], category: ICustomerCategory): JSX.Element => {
      const c: ICategory | undefined = categories.find(
        (categoryItem: ICategory): boolean =>
          categoryItem.uuid === category.uuid,
      );

      const categoryName: ITranslation | undefined = c?.names.find(
        (name: ITranslation): boolean =>
          name.code === Language.languageType.code,
      );

      return (
        <li>
          <Link
            to={`/${Language.languageType.code.toLowerCase()}/category/${
              category.uuid
            }`}
            className="breadcrumbs--category"
            onClick={(): void => {
              dispatch(reset());
            }}
          >
            {categoryName ? categoryName.value : '...'}
          </Link>
        </li>
      );
    },
    [Language.languageType.code, dispatch],
  );

  const getChildCategoryElement = useCallback(
    (
      categories: ICategory[],
      activeCategoryUUID: string,
    ): JSX.Element | null => {
      const parentCategory: ICategory | undefined = categories.find(
        (category: ICategory): boolean =>
          category.childCategoriesUUIDs.includes(activeCategoryUUID),
      );

      if (!parentCategory) {
        return null;
      }

      return getBreadcrumbElement(categories, parentCategory);
    },
    [getBreadcrumbElement],
  );

  const getProductElement = useCallback(
    (categories: ICustomerCategory[]): JSX.Element | null => {
      if (categories.length === 0) {
        return null;
      }

      const [category] = categories;

      if (!category) {
        return null;
      }

      return getBreadcrumbElement(Category.categories, category);
    },
    [getBreadcrumbElement, Category.categories],
  );

  useEffect((): void => {
    setDidMount(true);
  }, []);

  useEffect((): void => {
    setChildCategoryElement(
      getChildCategoryElement(Category.categories, Products.category.uuid),
    );
  }, [
    Category.categories,
    dispatch,
    Language.languageType.code,
    Products.category.uuid,
    getChildCategoryElement,
  ]);

  useEffect((): void => {
    if (!Product.product) {
      return;
    }

    setProductElement(getProductElement(Product.product.categories));
  }, [
    dispatch,
    Product.product,
    Language.languageType.code,
    Product.product?.categories,
    Products.category.uuid,
    getProductElement,
  ]);

  return (
    <ol className="breadcrumbs">
      <li>
        <Link
          to={`/${Language.languageType.code.toLowerCase()}/home`}
          className="breadcrumbs--home"
          onClick={(): void => {
            dispatch(reset());
          }}
        >
          <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3 2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
          </svg>
        </Link>
      </li>
      {didMount && childCategoryElement}
      {!didMount &&
        getChildCategoryElement(Category.categories, Products.category.uuid)}
      {didMount && productElement}
      {!didMount &&
        Product.product &&
        getProductElement(Product.product.categories)}
    </ol>
  );
}

const mapStateToProps = ({ Language, Product, Category, Products }) => ({
  Language,
  Product,
  Products,
  Category,
});

export default connect(mapStateToProps)(Breadcrumbs);
