import { type IProductState } from 'customer/react/reducers/product/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Grid from '../products/grid';

function Related({ Product }: { Product: IProductState }): JSX.Element | null {
  const { t } = useTranslation();

  if (!Product.product || Product.product?.relatedProducts.length === 0) {
    return null;
  }

  return (
    <div className="product related-products">
      <h2>{t('layout.relatedProducts')}</h2>
      <Grid products={Product.product.relatedProducts} headerLevel={3} />
    </div>
  );
}

const mapStateToProps = ({ Product }) => ({ Product });

export default connect(mapStateToProps)(Related);
