import React from 'react';

import { Add } from '../icons/add';

interface ButtonAddProps {
  onClick: () => void;
  text: string;
  isShown: boolean;
}

export function ButtonAdd({
  onClick,
  text,
  isShown,
}: ButtonAddProps): JSX.Element {
  return (
    <button
      className={`button--primary button--svg button--add${isShown ? '' : ' button--hidden'}`}
      onClick={onClick}
      type="button"
      disabled={!isShown}
    >
      <Add />

      <span>{text}</span>
    </button>
  );
}
