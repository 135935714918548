import React from 'react';

import Sidebar from '../components/sidebar';

function LayoutSidebarContent({
  element,
}: {
  element: JSX.Element;
}): JSX.Element {
  return (
    <>
      <Sidebar />

      <div className="content">{element}</div>
    </>
  );
}

export default LayoutSidebarContent;
