import React from 'react';
import { useTranslation } from 'react-i18next';

function ReviewStepFour(): JSX.Element {
  const { t } = useTranslation();

  return <p>{t('review.submitted')}</p>;
}

export default ReviewStepFour;
