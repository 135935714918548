import { createSlice } from '@reduxjs/toolkit';

import {
  type ILoad,
  type ILoadByBrand,
  type ILoadByBrandResponse,
  type ILoadResponse,
  type IProductsState,
  type ISetActiveCategory,
} from './types';

const initialState: IProductsState = {
  isLoading: false,
  category: {
    uuid: '',
  },
  products: [],
  pages: 1,
  page: 1,
};

const productsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    setActiveCategory: (state, action: ISetActiveCategory): void => {
      state.category = { uuid: action.payload.category.uuid };
    },
    load: (state, action: ILoad): void => {
      state.isLoading = true;
      state.category = { uuid: action.payload.category.uuid };
      state.page = action.payload.page;
    },
    loadResponse: (state, action: ILoadResponse): void => {
      state.products = action.payload.products;
      state.isLoading = false;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    loadBybrand: (state, action: ILoadByBrand): void => {
      state.isLoading = true;
      state.category = { uuid: action.payload.category.uuid };
      state.page = action.payload.page;
    },
    loadByBrandResponse: (state, action: ILoadByBrandResponse): void => {
      state.products = action.payload.products;
      state.isLoading = false;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
  },
});

export const {
  setActiveCategory,
  load,
  loadResponse,
  loadBybrand,
  loadByBrandResponse,
} = productsSlice.actions;

export default productsSlice.reducer;
