import { Field } from 'formik';
import React from 'react';

function Input({
  errorMessage = undefined,
  name,
  label,
  type = 'text',
  placeholder = '',
  onBlur,
}: {
  errorMessage?: string;
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}): JSX.Element {
  const hasError: boolean = !!errorMessage;

  return (
    <label htmlFor={name} className={hasError ? 'error' : 'valid'}>
      {label}

      <Field
        id={name}
        name={name}
        type={type}
        className={hasError ? 'error' : 'valid'}
        onBlur={onBlur}
        placeholder={placeholder}
      />

      <p className={`errorMessage ${errorMessage ? 'error' : 'valid'}`}>
        {errorMessage || '&nbsp;'}
      </p>
    </label>
  );
}

export default Input;
