import { type Dispatch } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import Footer from '../components/footer';
import Messenger from '../components/messenger';
import SearchResult from '../components/search-result';
import Topbar from '../components/topbar';
import { toggleSidebar } from '../reducers/config/reducer';
import {
  type IConfigState,
  type ToggleSidebar,
} from '../reducers/config/types';
import { type ITagManager } from '../types';
import BasketContainer from './basket-container';
import DeliveryContainer from './delivery-container';
import HomeContainer from './home-container';
import LayoutContent from './layout-content';
import LayoutSidebarContent from './layout-sidebar-content';
import OrderContainer from './order-container';
import ProductContainer from './product-container';
import ProductsContainer from './products-container';
import ReviewContainer from './review-container';
import ReviewsContainer from './reviews-container';
import TermsAndConditionsContainer from './terms-and-conditions-container';

function Layout({
  Config,
  dispatch,
  TagManager = undefined,
}: {
  Config: IConfigState;
  dispatch: Dispatch;
  TagManager: ITagManager | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="container">
      <Topbar />

      <div className="sidebar-menu">
        <button
          type="button"
          onClick={(): ToggleSidebar => dispatch(toggleSidebar({}))}
        >
          {Config.showSidebar
            ? `${t('layout.hideProductCategories')}`
            : `${t('layout.showProductCategories')}`}
        </button>
      </div>

      <SearchResult />

      <Routes>
        <Route
          index
          path="/:language"
          element={<LayoutSidebarContent element={<HomeContainer />} />}
        />
        <Route
          index
          path="/:language/home"
          element={<LayoutSidebarContent element={<HomeContainer />} />}
        />
        <Route
          path="/:language/basket"
          element={
            <LayoutSidebarContent
              element={<BasketContainer TagManager={TagManager} />}
            />
          }
        />
        <Route
          path="/:language/delivery"
          element={<LayoutSidebarContent element={<DeliveryContainer />} />}
        />
        <Route
          path="/:language/order/:orderUUID"
          element={<LayoutSidebarContent element={<OrderContainer />} />}
        />
        <Route
          path="/:language/product/:productUUID"
          element={
            <LayoutSidebarContent
              element={<ProductContainer TagManager={TagManager} />}
            />
          }
        />
        <Route
          path="/:language/p/:slug"
          element={
            <LayoutSidebarContent
              element={<ProductContainer TagManager={TagManager} />}
            />
          }
        />
        <Route
          path="/:language/category/:categoryUUID/:pageUrl"
          element={<LayoutSidebarContent element={<ProductsContainer />} />}
        />
        <Route
          path="/:language/category/:categoryUUID"
          element={<LayoutSidebarContent element={<ProductsContainer />} />}
        />
        <Route
          path="/:language/terms-and-conditions"
          element={
            <LayoutSidebarContent element={<TermsAndConditionsContainer />} />
          }
        />
        <Route
          path="/:language/review/:reviewUUID"
          element={<LayoutContent element={<ReviewContainer />} />}
        />
        <Route
          path="/:language/reviews/"
          element={<LayoutSidebarContent element={<ReviewsContainer />} />}
        />
      </Routes>

      <Messenger />

      <Footer />
    </div>
  );
}

const mapStateToProps = ({ Config }) => ({
  Config,
});

export default connect(mapStateToProps)(Layout);
