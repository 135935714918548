import { type IBasketProduct } from '@mahawi/eshop-common/dist/src/types';
import { createSlice } from '@reduxjs/toolkit';

import {
  AddProduct,
  AddProductResponse,
  type IBasketState,
  InitialStateResponse,
  RemoveProduct,
  RemoveProductResponse,
  SetDelivery,
  SetFields,
  ValidateDiscountCode,
  ValidateDiscountCodeResponse,
} from './types';

export const initialState: IBasketState = {
  inProgress: false,
  products: [],
  totalDeliveryFee: 0,
  packagesCount: 0,
  billingAddress: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
    countryTypeIsoCode: '',
  },
  deliveryAddress: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
    countryTypeIsoCode: '',
  },
  deliveryAddressIsDifferent: false,
  termsAndConditions: false,
  heurekaCZShopCertification: false,
  discount: {
    code: '',
    discountType: {
      code: '',
    },
    value: 0,
  },
  basketPrices: [],
};

const filterOutProduct = (
  products: IBasketProduct[],
  productUUID: string,
): IBasketProduct[] =>
  products.filter(({ uuid }: IBasketProduct): boolean => uuid !== productUUID);

const basketSlice = createSlice({
  name: 'Basket',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initState: (_state, _action): void => {},

    initStateResponse: (state, action: InitialStateResponse): void => {
      state.products = action.payload.products;
      state.basketPrices = action.payload.basketPrices;
      state.inProgress = false;

      state.products.sort((a, b) => a.uuid.localeCompare(b.uuid));
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addProduct: (state, _action: AddProduct): void => {
      state.inProgress = true;
      state.totalDeliveryFee = 0;
      state.packagesCount = 0;
    },

    addProductResponse: (state, action: AddProductResponse): void => {
      state.products = filterOutProduct(
        state.products,
        action.payload.product.uuid,
      );

      if (action.payload.product.count > 0) {
        state.products.push(action.payload.product);
      }

      state.inProgress = false;
      state.basketPrices = action.payload.basketPrices;

      state.products.sort((a, b) => a.uuid.localeCompare(b.uuid));
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeProduct: (state, _action: RemoveProduct): void => {
      state.inProgress = true;
      state.totalDeliveryFee = 0;
      state.packagesCount = 0;
    },

    removeProductResponse: (state, action: RemoveProductResponse): void => {
      state.products = filterOutProduct(
        state.products,
        action.payload.product.uuid,
      );
      state.inProgress = false;
      state.basketPrices = action.payload.basketPrices;
      state.totalDeliveryFee = 0;
      state.packagesCount = 0;

      state.products.sort((a, b) => a.uuid.localeCompare(b.uuid));
    },

    setDelivery: (state, action: SetDelivery): void => {
      state.totalDeliveryFee = action.payload.totalDeliveryFee;
      state.packagesCount = action.payload.packagesCount;
    },

    setFields: (state, action: SetFields): void => {
      state.billingAddress = action.payload.billingAddress;
      state.deliveryAddress = action.payload.deliveryAddress;
      state.termsAndConditions = action.payload.termsAndConditions;
      state.heurekaCZShopCertification =
        action.payload.heurekaCZShopCertification;
      state.deliveryAddressIsDifferent =
        action.payload.deliveryAddressIsDifferent;
      state.discount = {
        discountType: {
          code: '',
        },
        value: 0,
        code: action.payload.discount.code,
      };
    },
    validateDiscountCode: (state, action: ValidateDiscountCode): void => {
      state.inProgress = true;
      state.discount = {
        discountType: {
          code: '',
        },
        code: action.payload.discount.code,
        value: 0,
      };
    },
    validateDiscountCodeResponse: (
      state,
      action: ValidateDiscountCodeResponse,
    ): void => {
      state.inProgress = false;
      state.discount = {
        discountType: action.payload.discount.discountType,
        value: action.payload.discount.value,
        code: state.discount.code,
      };
      state.basketPrices = action.payload.basketPrices;
    },
  },
});

export const {
  initStateResponse,
  addProduct,
  initState,
  addProductResponse,
  removeProduct,
  removeProductResponse,
  setDelivery,
  setFields,
  validateDiscountCode,
  validateDiscountCodeResponse,
} = basketSlice.actions;

export default basketSlice.reducer;
