import React from 'react';
import { useTranslation } from 'react-i18next';

function ReviewRejected(): JSX.Element {
  const { t } = useTranslation();

  return <p>{t('review.rejected')}</p>;
}

export default ReviewRejected;
