import {
  type IProductProperty,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type ILanguageState } from 'customer/react/reducers/language/types';
import { type IProductState } from 'customer/react/reducers/product/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

function Properties({
  Product,
  Language,
}: {
  Product: IProductState;
  Language: ILanguageState;
}): JSX.Element {
  const { t } = useTranslation();

  if (
    !Product.product ||
    !Product.product.productProperties ||
    Product.product.productProperties.length === 0
  ) {
    return <div className="product properties container--3 container--t" />;
  }

  return (
    <div className="product properties container--3 container--t">
      <table>
        <caption>
          <h2>{t('product.properties')}</h2>
        </caption>
        <thead>
          <tr>
            <th>{t('product.propertyName')}</th>
            <th colSpan={2}>{t('product.propertyValue')}</th>
          </tr>
        </thead>
        <tbody>
          {Product.product?.productProperties
            .map((productProperty: IProductProperty): JSX.Element | null => {
              const name: ITranslation | undefined = productProperty.names.find(
                (n: ITranslation): boolean =>
                  n.code === Language.languageType.code,
              );

              let renderName: string = name ? name.value : productProperty.code;

              if (productProperty.unit) {
                renderName += ` [${productProperty.unit}]`;
              }

              const propertyValueName: ITranslation | undefined =
                productProperty.propertyValue?.names?.find(
                  (n: ITranslation): boolean =>
                    n.code === Language.languageType.code,
                );

              const value: string | undefined = propertyValueName
                ? propertyValueName.value
                : productProperty.value;

              if (!value) {
                return null;
              }

              return (
                <tr key={productProperty.code}>
                  <td>{renderName}</td>
                  <td>{value}</td>
                  <td>{productProperty.maxValue}</td>
                </tr>
              );
            })
            .filter((el: JSX.Element | null): boolean => el !== null)}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = ({
  Product,
  Language,
}: {
  Product: IProductState;
  Language: ILanguageState;
}): {
  Product: IProductState;
  Language: ILanguageState;
} => ({ Product, Language });

export default connect(mapStateToProps)(Properties);
